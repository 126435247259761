import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start
import React from 'react';
import * as Yup from "yup";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface BillingDetails {
  address: {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
  };
  email: string | null;
  name: string;
  phone: string | null;
}
interface Card {
  type: 'card';
  brand: string;
  last4: string;
  exp_month: number;
  exp_year: number;
  billing_details: BillingDetails;
}

interface BankAccount {
  bank_name: string;
  country: string;
  currency: string;
  last4: string;
  routing_number: string;
  account_holder_name: string;
  account_holder_type: string;
}

interface PaymentMethod {
  type: 'card' | 'ach';
  card?: Card;
  last4: string;
  brand: string;
  bank_accounts?: BankAccount[];
}

interface Subscription {
  cardNumber: string;
  stripe_subscription_id: string;
  amount: string;
  plan_name: string;
  start_date: string;
  payment_method: PaymentMethod | null;
  bank_accounts: BankAccount[];
}

interface BillingDetails2 {
  id: number;
  full_name: string | null;
  address2: string | null;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  created_at: string;
  updated_at: string;
  email: string | null;
  name2: string | null;
  phone: string | null;
}

interface InvoiceDetails {
  id: string;
  invoice_number: string;
  amount_due: number;
  amount_paid: number;
  amount_remaining: number;
  billing_reason: string;
  currency: string;
  description: string | null;
  due_date: string | null;
  hosted_invoice_url: string;
  invoice_pdf: string;
  status: string;
  total: number;
  period_end: number;
  period_start: number;
}


interface StateData {
  billing_details: BillingDetails2;
  invoice_details: InvoiceDetails;
}


const getDefaultBillingDetailsForPayments = (): BillingDetails2 => ({
  id: 0,
  full_name: null,
  address2: null, // Include new property
  city: null,
  state: null,
  zip_code: null,
  created_at: '', // Use empty string for date fields
  updated_at: '',
  email: null, 
  name2: null,  
  phone: null, // Include new property
});

const getDefaultInvoiceDetails = (): InvoiceDetails => ({
  id: '',
  invoice_number: '',
  amount_due: 0,
  amount_paid: 0,
  amount_remaining: 0,
  billing_reason: '',
  currency: '',
  description: null,
  due_date: null,
  hosted_invoice_url: '',
  invoice_pdf: '',
  status: '',
  total: 0,
  period_end: 0,
  period_start: 0
});


// Customizable Area End


interface S {
  // Customizable Area Start
  selectedPaymentMethod: string
  checked: boolean;
  paymentMethod: 'creditCard' | 'bankTransfer';
  showPaymentMethod: any;
  accountType: any;
  bankName: string;
  state: string;
  city: string;
  isPaymentModelOpen: boolean
  anchorEl: any;
  fullName: string,
  address: string,
  zipCode: string,
  cityName: string,
  stateName: string,
  methodModal: boolean;
  deleteModal: boolean;
  deleteSuccessfulModal: boolean;
  getPaymentID: string;
  sureDeleteModal: boolean;
  successFullModal: boolean;
  bankNumber: string;
  modelOpen: string;
  addNewPaymentModal: boolean;
  openSuccessModal:boolean;
  subscriptionDetails: Subscription[];
  PaymentDetails: StateData;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserSubscriptionDetailsId: string = '';
  deleteCardPaymentApiCallId: any;
  deleteBankPaymentApiCallId: any;
  getCardDetailsApiCallId: any;
  getBankDetailsApiCallId: any;
  validateLoginAccountId: string = '';
  getUserBillingDetailsId: string = '';
  submitBillingAddressId: string = '';
  createSubscriptionApiCallId: string = '';
  createSubscriptionBankApiCallId: string = '';
  getPaymentDetailsApiCallId:string = '';
  pdfRef: React.RefObject<any>;
  
  // Customizable Area End

  constructor(props: Props) {     
    super(props);
    this.receive = this.receive.bind(this);
    this.pdfRef = React.createRef<any>();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedPaymentMethod: 'VisaCard',
      checked: false,
      paymentMethod: 'creditCard',
      showPaymentMethod: '1',
      accountType: "",
      bankName: "",
      state: "",
      city: "",
      isPaymentModelOpen: false,
      anchorEl: null,
      fullName: "",
      address: "",
      zipCode: "",
      cityName: '',
      stateName: '',
      methodModal: false,
      deleteModal: false,
      deleteSuccessfulModal: false,
      getPaymentID: '',
      sureDeleteModal: false,
      successFullModal: false,
      bankNumber: '',
      modelOpen: '',
      addNewPaymentModal: false,
      openSuccessModal: false,
      subscriptionDetails: [],
      PaymentDetails: {
        billing_details: getDefaultBillingDetailsForPayments(),
        invoice_details: getDefaultInvoiceDetails()
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    

    if (responseJson) {
      if (apiRequestCallId === this.getUserSubscriptionDetailsId) {
        if (responseJson.subscriptions_history && responseJson.subscriptions_history.length > 0) {
          const currentSubscriptionData = responseJson.current_subscription;
          let cardLastDigits, cardBrandName, bankLastDigits, bankName;
          if (currentSubscriptionData.payment_method) {
            cardLastDigits = currentSubscriptionData.payment_method.last4;
            cardBrandName = currentSubscriptionData.payment_method.brand;
          } else if (currentSubscriptionData.bank_accounts && currentSubscriptionData.bank_accounts.length > 0) {
            bankLastDigits = currentSubscriptionData.bank_accounts[0].last4;
            bankName = currentSubscriptionData.bank_accounts[0].bank_name;
          }
          this.setState({
            subscriptionDetails: responseJson.subscriptions_history,
          });
        }
      } else if (apiRequestCallId === this.deleteCardPaymentApiCallId) {
        this.handleCardApi(responseJson);
      } else if (apiRequestCallId === this.deleteBankPaymentApiCallId) {
        this.handleBankApi(responseJson);
      } else if (apiRequestCallId === this.getCardDetailsApiCallId) {
        this.deleteCardPaymentMethod(responseJson[0].payment_method_id);
      } else if (apiRequestCallId === this.getBankDetailsApiCallId) {
        this.deleteBankPaymentMethod(responseJson[0].source_id)
      } else if (apiRequestCallId === this.createSubscriptionBankApiCallId) {
        this.setState({ addNewPaymentModal: false, openSuccessModal: true });
      } else if (apiRequestCallId === this.createSubscriptionApiCallId) {
        this.setState({ addNewPaymentModal: false, openSuccessModal: true });
      } else if (apiRequestCallId === this.getUserBillingDetailsId) {
        const attributes = responseJson[0];
        this.setState({
          fullName: attributes.full_name,
          address: attributes.address,
          cityName: attributes.city,
          stateName: attributes.state,
          zipCode: attributes.zip_code,
        });
      }
      else if(apiRequestCallId === this.getPaymentDetailsApiCallId){
      this.setState({
        PaymentDetails: {
          billing_details: responseJson.billing_details,
          invoice_details: responseJson.invoice_details
        },
      });      
      }
     
    }
    
   
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    console.log("response 295");
    
    this.getUserSubscriptionDetails();
    this.getUserBillingAddress();
    this.getPaymentDetailsApi();
  }

  paymentDetailsSchema = Yup.object({
    fullName: Yup.string()
      .required("Please enter your full name"),
    address: Yup.string()
      .required("Please enter your Address"),
    cityName: Yup.string()
      .required("Please select your City"),
    stateName: Yup.string()
      .required("Please select your State"),
    zipCode: Yup.string()
      .required("Please enter your zipCode"),
  });

  subscriptionClickBtns = () => {
    this.props.navigation.navigate("Subscription");
  }

  handlePaymentHistory = () => {
    this.props.navigation.navigate("PaymentHistory");
  }

  handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedPaymentMethod: event.target.value });
  };

  handleEditModal = () => {
    this.setState({ methodModal: true });
  }

  handleCardCloseModal = () => {
    this.setState({ methodModal: false });
  };

  handleDeleteModal = () => {
    this.getCardPaymentMethod();
  }

  handleSuccessModal = () => {
    this.setState({ successFullModal: true })
  }

  handleNewPaymentMethod = () => {
    this.setState({ addNewPaymentModal: true });
  }

  handleCloseNewPaymentMethod = () => {
    this.setState({ addNewPaymentModal: false });
  }

  handleSuccessCloseModal = () => {
    this.setState({ successFullModal: false })
  }

  handleSureDeleteModal = () => {
    this.setState({ sureDeleteModal: false })
  }

  handleDeletePaymentMethodModal = () => {
    this.setState({ methodModal: false, sureDeleteModal: true });
  }

  handleDeleteCloseModal = () => {
    this.setState({ sureDeleteModal: false, deleteModal: false });
  }

  handleDeleteSuccessModal = () => {
    this.setState({ deleteModal: false, deleteSuccessfulModal: true });
  }

  handleDeleteSuccessCloseModal = () => {
    this.setState({ deleteSuccessfulModal: false });
  }

  onSubmit = () => {
    this.setState({ selectedPaymentMethod: '' })
  }
  handleChange = (event: any) => {
    const checked = event.target?.checked;
    if (typeof checked !== 'undefined') {
      this.setState({ checked });
    }
  };

  handleToggleButton = (
    event: React.MouseEvent<HTMLElement>,
    newPaymentMethod: 'creditCard' | 'bankTransfer'
  ) => {
    if (newPaymentMethod !== null) {
      this.setState({ paymentMethod: newPaymentMethod });
    }
  };

  handleContinue = () => {
    this.setState({ showPaymentMethod: "2" });
  };

  handleAccountType = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target || { value: undefined };
    this.setState({ accountType: value as string });
  }

  handleBankName = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { value } = event.target || { value: undefined };
    this.setState({ bankName: value as string || '' });
  };


  handleStateChange = (event: any) => {
    this.setState({ state: event.target.value as string });
  };
  handleCityChange = (event: any) => {
    this.setState({ city: event.target.value as string });
  };
  handleOpenModal = () => {
    this.setState({ isPaymentModelOpen: true });
  };

  handleCloseModal = () => {
    this.setState({ isPaymentModelOpen: false });
  };

  handlePopoverOpen = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  handleBankApi = (responseJson:any) => {
    if(responseJson.message == "Cannot delete the last bank"){
      this.setState({ methodModal:false, deleteModal: true });
    }
    else{
      this.setState({sureDeleteModal:false,successFullModal: true})
    }
  }

  handleCardApi = (responseJson:any) => {
    if(responseJson.message == "Cannot delete the last card"){
      this.setState({ methodModal:false, deleteModal: true });
    }
    else{
      this.setState({sureDeleteModal:false,successFullModal: true})
    }
  }

  getUserSubscriptionDetails = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserSubscriptionDetailsId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/get_subscription`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  getCardPaymentMethod = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCardDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/get_card`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  };

  getBankPaymentMethod = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getBankDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/get_bank`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  };

  getUserBillingAddress = () => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserBillingDetailsId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/get_billing_address`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }

  submitBillingAddressDetails = (values: any) => {
    let token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append('full_name', values.fullName);
    formData.append('address', values.address);
    formData.append('city', values.cityName);
    formData.append('state', values.stateName);
    formData.append('zip_code', values.zipCode);

    const header = { token: token };

    this.setState({
      fullName: values.fullName,
      address: values.address,
      cityName: values.cityName,
      stateName: values.stateName,
      zipCode: values.zipCode
    })

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.submitBillingAddressId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/billing_address`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true
  }

  deleteCardPaymentMethod = (getPaymentID: any) => {
    let token = localStorage.getItem("token");
    const header = { "Content-Type": configJSON.validationApiContentType, token: token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteCardPaymentApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/delete_card` + "?id=" + getPaymentID);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteBankPaymentMethod = (getPaymentID: any) => {
    let token = localStorage.getItem("token");
    const header = { token: token };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteBankPaymentApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_subscriptionbilling/delete_bank` + "?id=" + getPaymentID);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPaymentDetailsApi = () => {
    console.log("response 561");
    

    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPaymentDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPaymentDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleDownload = () => {
    const input = this.pdfRef.current;
    if (input) {
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save("download.pdf");
        });
    }
};

handleSubscription = () => {
  this.props.navigation.navigate('Subscription')
}


handlePayments = () => {
  this.props.navigation.navigate('Subscriptionbilling')
}


  // Customizable Area End
}