import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Divider,
    Paper,
    InputBase,
    Checkbox,
    Popover,
    Dialog,
    DialogContent,
    DialogActions,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Card,
    CardContent,
    CardMedia,
    Avatar,
    ListItemAvatar,
    Menu,
    DialogTitle,
    InputLabel,
    FormControl,
    Select
} from "@material-ui/core";
import SideDrawer from "../../../components/src/SideDrawer";
import Header from "../../../components/src/Header.web";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import { styled } from "@material-ui/core/styles";
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@material-ui/icons/Star';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchIcon from "@mui/icons-material/Search";
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

// Customizable Area End
import DashboardController, {
    Props,
} from "./DashboardController.web";
import { addIcon, avatar, backIcon, cameraIcon, categoryIcon, choclateCake, dummyImage, greenTick, iIcon, locationIcon, momentIcon, profileIcon, profilePhoto, recipeIcon, sharePeople } from "./assets";

export default class UserProfileViewing extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { selectedTab, anchorEl, menuItems, year, month, dateOpen, datePopOver } = this.state;
        const people = [
            { name: 'Andrea', count: 10, avatar: 'https://example.com/andrea.jpg' },
            { name: 'Sofi', count: 2, avatar: 'https://example.com/sofi.jpg' },
            { name: 'Kate', count: 7, avatar: 'https://example.com/kate.jpg' },
            { name: 'Kevin', count: 28, avatar: 'https://example.com/kevin.jpg' },
            { name: 'Joseph', count: 48, avatar: 'https://example.com/joseph.jpg' },
            { name: 'Sarah', count: 5, avatar: 'https://example.com/sarah.jpg' },
        ];
        const open = !!anchorEl;
        const id = open ? 'shared-with-popover' : undefined;
        const timelineEvents = [
            { date: '19. October 1994', title: 'Born', location: '' },
            { date: '1998', title: 'Graduated High School', location: 'Riverside High School' },
            { date: '1993', title: 'Bachelor of Science', location: '' },
            { date: '1996', title: 'MBA', location: '' },
            { date: '25. June 2005', title: 'Married', location: 'Peter Johnson' }
        ];

        const contributors = [
            { name: 'You', role: 'Owner' },
            { name: 'Peter', role: 'Viewer' },
            { name: 'Karen', role: 'Contributor' },
            { name: 'Karla', role: 'Contributor' },
            { name: 'Andreas', role: 'Contributor' },
        ];
        const menus = Boolean(menuItems);
        const years = Array.from(new Array(30), (val, index) => 2000 + index);
        const months = [
            'January', 'February', 'March', 'April', 'May', 'June', 'July',
            'August', 'September', 'October', 'November', 'December'
        ];

        const isPopoverOpen = Boolean(datePopOver);
        return (
            //Merge Engine DefaultContainer
            <UserProfileViewingStyle>
                <SideDrawer />
                <Box className="MainContent">
                    <Box>
                        {
                            this.state.authToken ? (
                                <HeaderDropdown
                                    data-test-id="headerComponenttestid"
                                    navigation={undefined}
                                    id={""}
                                />
                            ) : (
                                <Header onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} getHeaderData={() => { }} />
                            )
                        }
                    </Box>
                    <Box
                        className="NameWithBackIconBox"
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: 20 }}
                    >
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={backIcon} className="backIcon" />
                            <Typography className="SharingText" style={{ marginLeft: '8px' }}>
                                Sharing - Kate
                            </Typography>
                        </Box>
                        <Box>
                            <img src={sharePeople} />
                            <img src={addIcon} style={{ marginLeft: '8px', cursor: 'pointer' }} data-test-id="hanldeClickOPenCover" onClick={this.handleClickOpen} />
                            <div>
                                <Dialog
                                    open={this.state.open}
                                    data-test-id="handleCloseProfile"
                                    onClose={this.handleCloseProfileModal}
                                    maxWidth="sm"
                                    PaperProps={{
                                        style: {
                                            width: '580px',
                                            borderRadius: '8px'
                                        },
                                    }}
                                >
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', padding: 15, alignItems: 'center' }}>
                                        <Typography style={{ fontSize: 22, fontWeight: 700, fontFamily: "Inter", color: '#1C2110' }}>Add people to share with</Typography>
                                        <CloseIcon style={{ cursor: 'pointer', color: '#334155' }} data-test-id="handleCloseProfile" onClick={this.handleCloseProfileModal} />
                                    </Box>
                                    <Divider />
                                    <DialogContent>

                                        <Typography style={{ fontSize: 16, fontWeight: 700, fontFamily: "Poppins", color: '#1C2110' }}>
                                            Actual owners and contributors
                                        </Typography>


                                        <List style={{ display: "flex", flexWrap: 'wrap', maxWidth: "600" }}>
                                            {contributors.map((contributor, index) => (
                                                <ListItem key={index} style={{
                                                    width: "fit-content",
                                                    boxSizing: "border-box"
                                                }}>
                                                    <ListItemAvatar>
                                                        <Avatar alt={contributor.name} src={avatar} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={contributor.name}
                                                        secondary={contributor.role}
                                                        primaryTypographyProps={{ style: { fontWeight: 700 } }}
                                                    />

                                                </ListItem>
                                            ))}
                                        </List>

                                        <Typography style={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins', color: '#1C2110' }}>
                                            People, family or friends
                                        </Typography>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            height: '56px',
                                            padding: '0 10px',
                                            marginTop: '10px'
                                        }}>
                                            <input
                                                type="text"
                                                id="add-people-input"
                                                placeholder="Add people who you want to share this file"
                                                style={{
                                                    maxWidth: '497px',
                                                    width: '100%',
                                                    fontSize: '14px',
                                                    border: 'none',
                                                    outline: 'none',
                                                }}
                                            />
                                            <div style={{ display: 'flex', alignItems: 'center', margin: 'auto', marginLeft: 40 }}>
                                                <img src={iIcon} style={{ width: 20, height: 20 }} />

                                                <Button
                                                    style={{
                                                        color: '#557C29',
                                                        cursor: 'pointer',
                                                        textTransform: 'none',
                                                        fontWeight: 700,
                                                        fontFamily: 'Poppins',
                                                        fontSize: 14
                                                    }}
                                                    onClick={() => console.log('Handle dropdown logic')}
                                                >
                                                    Contributor
                                                    <KeyboardArrowDownIcon style={{ marginLeft: '8px' }} />
                                                </Button>
                                            </div>
                                            {/* Info icon next to the dropdown */}

                                        </div>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Typography style={{ fontWeight: 700, fontSize: 16, fontFamily: 'Poppins', color: '#1C2110' }}>
                                                Shareable link
                                            </Typography>

                                            {/* Right side - Contributors button and info icon */}
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                {/* Info Icon */}
                                                <img src={iIcon} alt="info icon" style={{ width: 20, height: 20 }} />

                                                {/* Contributors Button */}
                                                <Button
                                                    style={{
                                                        color: '#557C29',
                                                        cursor: 'pointer',
                                                        textTransform: 'none',
                                                        fontWeight: 700,
                                                        fontFamily: 'Poppins',
                                                        fontSize: 14
                                                    }}
                                                    onClick={() => console.log('Handle dropdown logic')}
                                                >
                                                    Contributor
                                                    <KeyboardArrowDownIcon style={{ marginLeft: '8px' }} />
                                                </Button>
                                            </div>
                                        </Box>
                                        <Typography style={{ fontSize: 14, fontFamily: 'Poppins', fontWeight: 400 }}>Copy this link if you want to share with any user who has the link.</Typography>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            height: '56px',
                                            padding: '0 10px',
                                            marginTop: '10px'
                                        }}>
                                            <input
                                                type="text"
                                                id="add-people-input"
                                                placeholder="Add people who you want to share this file"
                                                style={{
                                                    maxWidth: '497px',
                                                    width: '100%',
                                                    fontSize: '14px',
                                                    border: 'none',
                                                    outline: 'none',
                                                }}
                                            />


                                        </div>

                                    </DialogContent>

                                    <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: 30, marginTop: 10 }}>
                                        <Button style={{ width: "141px", height: 56, borderRadius: 8, border: "1px solid #557C29", backgroundColor: '#F8FFE7' }}
                                            data-test-id="handleCloseDataTestId" onClick={this.handleClose}
                                        >
                                            <Typography style={{ fontWeight: 700, fontSize: 16, color: "#557C29", textTransform: 'none' }}>Copy link</Typography>
                                        </Button>
                                        <Button style={{ backgroundColor: "#557C29", width: "141px", height: 56, borderRadius: 8, textTransform: 'none' }}
                                            data-test-id="handleCloseDataTestId" onClick={this.handleClose}
                                        >
                                            <Typography style={{ fontWeight: 700, fontSize: 16, fontFamily: "Poppins", color: "#fff" }}>Done</Typography>

                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </Box>
                    </Box>
                    <Box style={{ marginLeft: 30, display: 'flex', marginTop: 50 }}>
                        <Box style={{ position: 'relative', display: 'inline-block' }}>
                            <img src={profilePhoto} style={{ width: '293px', height: "293px", borderRadius: '16px' }} />
                            <img
                                src={addIcon}
                                style={{
                                    position: 'absolute',
                                    top: '230px',
                                    right: '10px',
                                    width: '49px',
                                    height: '49px',
                                    cursor: 'pointer'
                                }}
                                data-test-id="handleMenuItemsCover"
                                onClick={this.handleMenuItemClick}
                            />
                        </Box>
                        <Menu
                            anchorEl={menuItems}
                            open={menus}
                            data-test-id="handleMenuClosetestCase"
                            onClose={this.handleMenuItemClose}
                            style={{
                                width: '210px',
                                borderRadius: '8px',
                                marginTop: 60
                            }}
                        >
                            <MenuItem onClick={this.handleMenuItemClose}>
                                <img src={cameraIcon} style={{ width: 18, height: 18, marginRight: 15 }} />
                                Photo
                            </MenuItem>
                            <MenuItem onClick={this.handleMenuItemClose}>
                                <img src={momentIcon} style={{ width: 18, height: 18, marginRight: 15 }} />
                                Moment
                            </MenuItem>
                            <MenuItem onClick={this.handleMenuItemClose}>
                                <img src={recipeIcon} style={{ width: 18, height: 18, marginRight: 15 }} />
                                Recipe
                            </MenuItem>
                            <MenuItem onClick={this.handleMenuItemClose}>
                                <img src={profileIcon} style={{ width: 18, height: 18, marginRight: 15 }} />
                                Profile Picture
                            </MenuItem>
                        </Menu>
                        <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: 50 }}>
                            <Typography className="nameText">Kate</Typography>
                            <Typography className="nameTextRelation">Sister</Typography>
                            <Divider
                                style={{
                                    width: '810px',
                                    marginTop: 10,
                                    backgroundColor: '#D6D3D1',
                                }}
                            />
                            <Box style={{ marginTop: 40, display: 'flex', justifyContent: 'space-between' }}>
                                <Box style={{ width: '45%' }}>
                                    <Typography style={{ fontSize: "18px", fontFamily: "Poppins", fontWeight: 400, color: '#6d9332' }}>
                                        Personal Info
                                    </Typography>
                                    <Box style={{ marginTop: 20 }}>
                                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                            <Typography style={{ fontWeight: 700, fontSize: '16px', color: '#5D6063' }}>Birthday:</Typography>
                                            <Typography style={{ marginLeft: 'auto', fontWeight: 400, fontSize: '16px', color: '#5D6063', width: '50%' }}>19. October 1994</Typography>
                                            <IconButton size="small" style={{ marginLeft: 10 }}>
                                                <EditIcon style={{ color: '#6d9332' }} />
                                            </IconButton>
                                        </Box>
                                        <Divider />
                                        <Box style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                            <Typography style={{ fontWeight: 700, fontSize: '16px', color: '#5D6063' }}>Nickname:</Typography>
                                            <Typography style={{ marginLeft: 'auto', fontWeight: 400, fontSize: '16px', color: '#5D6063', width: '50%' }}>Katy</Typography>
                                            <IconButton size="small" style={{ marginLeft: 10 }}>
                                                <EditIcon style={{ color: '#6d9332' }} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box style={{ width: '45%' }}>
                                    <Typography style={{ fontSize: "18px", fontFamily: "Poppins", fontWeight: 400, color: '#6d9332' }}>
                                        Family Members
                                    </Typography>
                                    <Box style={{ marginTop: 20 }}>
                                        {[
                                            { relation: 'Husband', name: 'Peter Johnson' },
                                            { relation: 'Daughter', name: 'Miah Johnson' },
                                            { relation: 'Son', name: 'Sam Johnson' },
                                            { relation: 'Mom', name: 'Karen Peterson' },
                                            { relation: 'Dad', name: 'Henry Peterson' },
                                            { relation: 'Stepdad', name: 'Doug Akers' },
                                        ].map((member, index) => (
                                            <Box
                                                key={index}
                                                style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}
                                            >
                                                <Typography style={{ fontWeight: 700, fontSize: '16px', color: '#5D6063' }}>{member.relation}:</Typography>
                                                <Typography style={{ marginLeft: 'auto', fontWeight: 400, fontSize: '16px', color: '#5D6063', width: '50%' }}>{member.name}</Typography>
                                                <IconButton size="small" style={{ marginLeft: 10 }}>
                                                    <EditIcon style={{ color: '#6d9332' }} />
                                                </IconButton>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </Box>

                    <Box style={{ marginLeft: '30px', marginTop: '30px' }}>
                        <Typography className="filterText">Filter by</Typography>
                        <Box className="filterMainBox">
                            <Box className="filterSubBox">
                                <Box className="filterBox">
                                    <StarIcon />
                                    <Typography className="filterBoxText" style={{ marginLeft: '2px' }}>Favorites</Typography>
                                </Box>
                                <Button className="filterBox" style={{ marginLeft: '7px' }}>
                                    <Typography className="filterBoxText">Media Type</Typography>
                                    <IconButton><KeyboardArrowDownIcon /></IconButton>
                                </Button>
                                <Button className="filterBox" data-test-id="handleOpenPopTestId" onClick={this.handleOpenPopover}>
                                    <Typography className="filterBoxText">Date</Typography>
                                    <IconButton><KeyboardArrowDownIcon /></IconButton>
                                </Button>

                                <Popover
                                    open={isPopoverOpen}
                                    anchorEl={datePopOver}
                                    data-test-id = "handleCloseTestId"
                                    onClose={this.handleClosePopover}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "left",
                                    }}
                                    PaperProps={{
                                        style: { padding: '8px', width: '200px' }, // Compact the dropdown's padding and width
                                    }}
                                >
                                    {/* Year selection */}
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Year</InputLabel>
                                        <Select value={year} data-test-id="handleYearsTestId" onChange={this.handleYearChange}>
                                            <MenuItem value=""><em>Choose Year</em></MenuItem>
                                            {years.map((year) => (
                                                <MenuItem key={year} value={year}>
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    {/* Month selection */}
                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Month</InputLabel>
                                        <Select value={month} data-test-id="handleMonthTestId" onChange={this.handleMonthChange}>
                                            <MenuItem value=""><em>Choose Month</em></MenuItem>
                                            {months.map((month, index) => (
                                                <MenuItem key={index} value={month}>
                                                    {month}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Popover>
                                <Button className="filterBox">
                                    <Typography className="filterBoxText">Things</Typography>
                                    <IconButton><KeyboardArrowDownIcon /></IconButton>
                                </Button>
                                <Button className="filterBox">
                                    <Typography className="filterBoxText">People</Typography>
                                    <IconButton><KeyboardArrowDownIcon /></IconButton>
                                </Button>
                                <Button className="filterBox">
                                    <Typography className="filterBoxText">Location</Typography>
                                    <IconButton><KeyboardArrowDownIcon /></IconButton>
                                </Button>
                                <Button className="filterBox" data-test-id="handleOpenClickTest" onClick={this.handleClick}>
                                    <Typography className="filterBoxText">Shared with</Typography>
                                    <IconButton>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                </Button>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={this.state.anchorEl}
                                    data-test-id="handleCloseDataTestId"
                                    onClose={this.handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    PaperProps={{
                                        style: {
                                            marginTop: '30px',
                                        }
                                    }}
                                >
                                    <div style={{ width: "300px", padding: '10px' }}>
                                        <div style={{ paddingBottom: '10px' }}>
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                style={{
                                                    width: '100%',
                                                    padding: '8px',
                                                    borderRadius: '8px',
                                                    border: '1px solid #ddd',
                                                    fontFamily: 'Poppins',
                                                    fontSize: '14px',
                                                }}
                                            />
                                        </div>

                                        <List>
                                            {people.map((person) => (
                                                <ListItem key={person.name} style={{ paddingLeft: '0' }}>
                                                    <Checkbox
                                                        checked={this.state.selectedPeople.includes(person.name)}
                                                        data-test-id="handleToogleTestCoverage"
                                                        onChange={() => this.handleToggle(person.name)}
                                                        icon={
                                                            <div
                                                                style={{
                                                                    border: '1px solid #333',
                                                                    width: 20,
                                                                    height: 20,
                                                                    borderRadius: '4px',
                                                                    boxSizing: 'border-box',
                                                                }}
                                                            />
                                                        }
                                                        checkedIcon={
                                                            <div
                                                                style={{
                                                                    backgroundColor: '#557C29',
                                                                    width: 20,
                                                                    height: 20,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    borderRadius: '4px',
                                                                }}
                                                            >
                                                                <CheckIcon style={{ color: 'white', fontSize: 18 }} />
                                                            </div>
                                                        }
                                                    />
                                                    <Avatar src={person.avatar} alt={person.name} style={{ marginRight: 10 }} />
                                                    <ListItemText
                                                        primary={
                                                            <Typography style={{ fontSize: 14, fontFamily: 'Poppins', fontWeight: 500 }}>
                                                                {person.name}
                                                            </Typography>
                                                        }
                                                    />
                                                    <Typography style={{
                                                        fontSize: 12,
                                                        color: '#6b7280',
                                                        backgroundColor: '#f7f0f0',
                                                        borderRadius: '40px',
                                                        width: '26px',
                                                        height: '18px',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        fontFamily: 'Poppins',
                                                    }}>{person.count}</Typography>
                                                </ListItem>
                                            ))}
                                        </List>

                                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                            <Button
                                                style={{
                                                    backgroundColor: '#557C29',
                                                    color: 'white',
                                                    width: '268px',
                                                    padding: '6px 10px',
                                                    fontFamily: 'Poppins',
                                                    fontWeight: 600,
                                                    borderRadius: 8,
                                                    textTransform: "none"
                                                }}
                                                data-test-id="handleCloseDataTestId"
                                                onClick={this.handleClose}
                                            >
                                                Done
                                            </Button>
                                        </div>

                                    </div>
                                </Popover>
                                <Divider orientation="vertical" flexItem />
                                <Box style={{ marginLeft: '15px' }} className="filterBox">
                                    <img src={categoryIcon} />
                                    <IconButton><KeyboardArrowDownIcon /></IconButton>
                                </Box>
                            </Box>
                            <div className="filterBox" style={{ position: "relative", marginRight: 18 }}>
                                <div
                                    className="filterBoxText"
                                    style={{
                                        backgroundColor: "white",
                                        borderRadius: "8px",
                                        display: "flex",
                                        alignItems: "center",
                                        border: 'none',
                                        justifyContent: "space-between",
                                        cursor: "pointer",
                                    }}
                                    data-test-id="toggleDropdownTestId"
                                    onClick={this.toggleDropdownOpen}
                                >
                                    {this.state.selectedOption}
                                    <IconButton size="small">
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                </div>

                                {this.state.isOpen && (
                                    <div
                                        className="dropdownMenu"
                                        style={{
                                            position: "absolute",
                                            top: "100%",
                                            right: 20,
                                            width: "192px",
                                            borderRadius: "8px",
                                            border: "1px solid #ddd",
                                            backgroundColor: "white",
                                            zIndex: 10,
                                            overflow: "hidden",
                                            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                        }}
                                    >
                                        <div
                                            className="dropdownOption"
                                            data-test-id="selectOptions"
                                            style={{
                                                padding: "10px",
                                                cursor: "pointer",
                                                fontFamily: "Poppins",
                                                fontSize: 14,
                                                fontWeight: 400,
                                                display: "flex",
                                                color: "#0F172A",
                                                alignItems: "center",
                                            }}
                                            onClick={() => this.handleSelect("Most recent")}
                                        >
                                            <div
                                                style={{
                                                    marginRight: "8px",
                                                    width: "20px",
                                                }}
                                            >
                                                {this.state.selectedOption === "Most recent" && (
                                                    <img src={greenTick} alt="Selected" />
                                                )}
                                            </div>
                                            <Typography
                                                style={{
                                                    fontFamily: "Poppins",
                                                    fontSize: 14,
                                                    color: "#1C1917",
                                                    fontWeight: 400,
                                                }}
                                            >
                                                Most recent
                                            </Typography>
                                        </div>
                                        <div
                                            data-test-id="selectOptions"
                                            className="dropdownOption"
                                            style={{
                                                cursor: "pointer",
                                                fontSize: 14,
                                                fontWeight: 400,
                                                padding: "10px",
                                                color: "#0F172A",
                                                fontFamily: "Poppins",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                            onClick={() => this.handleSelect("Least recent")}
                                        >
                                            <div
                                                style={{
                                                    marginRight: "8px",
                                                    width: "20px",
                                                }}
                                            >
                                                {this.state.selectedOption === "Least recent" && (
                                                    <img src={greenTick} alt="Selected" />
                                                )}
                                            </div>
                                            <Typography
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: 14,
                                                    color: "#1C1917",
                                                    fontFamily: "Poppins",
                                                }}
                                            >
                                                Least recent
                                            </Typography>
                                        </div>
                                    </div>
                                )}


                            </div>
                        </Box>
                        <Box style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                            <Paper style={{ width: '100%', border: '1px solid #D6D3D1', borderRadius: '40px', maxWidth: '1250px', margin: 'auto' }}>
                                <IconButton type="button" style={{ padding: "10px", color: "#A8A29E" }} aria-label="search">
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    data-test-id="inputBoxId"
                                    style={{ marginLeft: 1, color: "#A8A29E", fontSize: '16px', fontWeight: 400, width: '90%' }}
                                    placeholder="Search"
                                />
                                <IconButton type="button" style={{ padding: "10px", color: "#A8A29E", marginLeft: 30 }} aria-label="search">
                                    <PhotoCameraOutlinedIcon />
                                </IconButton>
                            </Paper>
                        </Box>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '488px',
                                padding: '10px',
                                backgroundColor: '#f7f0f0',
                                borderRadius: '20px',
                                marginTop: 40,
                            }}
                        >
                            <div
                                style={{
                                    padding: '15px 30px',
                                    borderRadius: '10px',
                                    border: selectedTab === 'photos' ? '1px solid green' : 'none',
                                    backgroundColor: selectedTab === 'photos' ? '#F7FEE7' : 'transparent',
                                    color: selectedTab === 'photos' ? '#557C29' : '#A8A29E',
                                    cursor: 'pointer',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    margin: '0 10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: 160,
                                }}
                                data-test-id="tabChangeClick"
                                onClick={() => this.handleTabChange('photos')}
                            >
                                Photos
                            </div>
                            <div
                                style={{
                                    padding: '15px 30px',
                                    borderRadius: '10px',
                                    border: selectedTab === 'moments' ? '1px solid green' : 'none',
                                    backgroundColor: selectedTab === 'moments' ? '#F7FEE7' : 'transparent', // Same background as selected photos tab
                                    color: selectedTab === 'moments' ? '#557C29' : '#A8A29E', // Same color logic for selected/unselected state
                                    cursor: 'pointer',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    margin: '0 10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: 160,
                                }}
                                onClick={() => this.handleTabChange('moments')}
                            >
                                Moments
                            </div>

                            <div
                                style={{
                                    padding: '15px 30px',
                                    borderRadius: '10px',
                                    border: selectedTab === 'recipes' ? '1px solid green' : 'none',
                                    backgroundColor: selectedTab === 'recipes' ? '#F7FEE7' : 'transparent',
                                    color: selectedTab === 'recipes' ? '#557C29' : '#A8A29E', // Same logic as photos and moments for color and background
                                    cursor: 'pointer',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    margin: '0 10px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: 160,
                                }}
                                onClick={() => this.handleTabChange('recipes')}
                            >
                                Recipes
                            </div>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            {selectedTab === 'photos' && (
                                <>
                                    <Button className="expandBoxs" data-test-id="handlePhotoExpand" onClick={this.handlePhotoExpand}>
                                        <Typography className="expandBtnText">Photos</Typography>
                                        <IconButton>{this.state.isPhotoExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                                    </Button>
                                    {this.state.isPhotoExpanded && (
                                        <>
                                            <Box style={{ display: 'flex', gap: '10px' }}>
                                                <Box className="photoExpandBox">
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
                                                        <Checkbox style={{ color: "#557C29" }} />
                                                        <IconButton>
                                                            <MoreVertIcon style={{ color: 'white' }} />
                                                        </IconButton>
                                                    </Box>
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '90px', padding: '22px' }}>
                                                        <Box>
                                                            <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                                            <img src={avatar} style={{ marginLeft: '-15px', height: '26px', width: '26px' }} />
                                                        </Box>
                                                        <Box style={{ display: 'flex' }}>
                                                            <ChatBubbleOutlineIcon style={{ color: 'white' }} />
                                                            <Typography style={{ color: 'white', marginLeft: '1px' }}>2</Typography>
                                                            <FavoriteIcon style={{ color: 'red', marginLeft: '3px' }} />
                                                            <Typography style={{ color: 'white' }}>3</Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                </>
                            )}

                            {selectedTab === 'moments' && (
                                <div>
                                    <h2>Moments Section</h2>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {timelineEvents.map((event, index) => (
                                            <div key={index} style={{ display: 'flex', marginBottom: '30px', alignItems: 'center' }}>

                                                {/* Left Section: 45% width */}
                                                <div style={{ width: '45%', textAlign: 'right', paddingRight: '10px' }}>
                                                    <p style={{ fontSize: '16px', fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>{event.date}</p>
                                                </div>

                                                {/* Middle Section: 10% width, for the circle */}
                                                <div style={{ width: '10%', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                                                    <div style={{
                                                        width: '24px',
                                                        height: '24px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#C2E176',
                                                        border: '3px solid #8CA74B'
                                                    }}></div>
                                                    {index !== timelineEvents.length - 1 && (
                                                        <div style={{
                                                            position: 'absolute',
                                                            top: '30px',
                                                            width: '3px',
                                                            height: '50px',
                                                            background: 'linear-gradient(90deg, #919191, #FFFFFF)',
                                                        }}></div>
                                                    )}
                                                </div>

                                                {/* Right Section: 45% width */}
                                                <div style={{ width: '45%', textAlign: 'left', paddingLeft: '10px', display: 'flex', gap: "30px" }}>
                                                    <p style={{ fontSize: '16px', fontWeight: 400, fontFamily: 'Poppins', color: '#5D6063', margin: 0 }}>{event.title}</p>
                                                    {event.location === "Riverside High School" ? (
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                            <img src={locationIcon} alt="Location Icon" />
                                                            <p style={{ fontSize: '16px', fontWeight: 700, fontFamily: 'Poppins', color: '#557C29', margin: 0, marginTop: -1 }}>
                                                                {event.location}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        /* Location Section for Other Locations without Icon */
                                                        event.location && (
                                                            <p style={{ fontSize: '16px', fontWeight: 700, fontFamily: 'Poppins', color: '#557C29', margin: 0, marginTop: -1 }}>
                                                                {event.location}
                                                            </p>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {selectedTab === 'recipes' && (
                                <div>
                                    <h2>Recipes Section</h2>
                                    <div>
                                        <Button className="expandBoxs" data-test-id="handlePhotoExpand" onClick={this.handlePhotoExpand}>
                                            <Typography className="expandBtnText">Recipes</Typography>
                                            <IconButton>{this.state.isPhotoExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                                        </Button>
                                        {this.state.isPhotoExpanded && (
                                            <div style={{ display: 'flex', gap: 20 }}>
                                                <Card style={{ maxWidth: 282, borderRadius: '8px 8px 32px 8px', boxShadow: '3px' }}>
                                                    <Box style={{ position: 'relative', display: 'inline-block' }}>
                                                        <CardMedia
                                                            component="img"
                                                            src={choclateCake}
                                                            height="180"
                                                            alt="Dessert"
                                                            style={{ borderRadius: '10px 10px 0 0' }}
                                                        />
                                                        <Box style={{
                                                            position: 'absolute',
                                                            left: 0,
                                                            top: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            alignItems: 'flex-start',
                                                            justifyContent: 'space-between',
                                                            padding: '10px'
                                                        }}>
                                                            <Checkbox style={{ color: "white" }} />
                                                            <IconButton>
                                                                <MoreVertIcon style={{ color: 'white', marginTop: '-2' }} />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                    <CardContent>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                                <Typography style={{ fontSize: 14, fontFamily: "Poppins", fontWeight: 400 }}>
                                                                    Cake
                                                                </Typography>

                                                                <Box style={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
                                                                    <ChatBubbleOutlineIcon style={{ color: '#78716C' }} />
                                                                    <Typography style={{ color: '#78716C', marginLeft: '1px' }}>2</Typography>
                                                                    <FavoriteIcon className="favIconStyle" />
                                                                    <Typography style={{ color: '#78716C' }}>3</Typography>
                                                                    <MoreVertIcon />
                                                                </Box>
                                                            </Box>

                                                        </Box>

                                                        <Box style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                                            <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                                            <img src={avatar} style={{ marginLeft: '-15px', height: '26px', width: '26px' }} />
                                                            <Typography style={{ fontSize: 12, fontWeight: 400, fontFamily: "Poppins", color: "#5D6063", marginLeft: 10 }}>
                                                                Kate & me
                                                            </Typography>
                                                        </Box>


                                                    </CardContent>
                                                </Card>
                                                <Card style={{ maxWidth: 282, borderRadius: '8px 8px 32px 8px', boxShadow: '3px' }}>
                                                    <Box style={{ position: 'relative', display: 'inline-block' }}>
                                                        <CardMedia
                                                            component="img"
                                                            alt="Dessert"
                                                            height="180"
                                                            src={choclateCake}
                                                            style={{ borderRadius: '10px 10px 0 0' }}
                                                        />
                                                        <Box style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            width: '100%',
                                                            left: 0,
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            height: '100%',
                                                            alignItems: 'flex-start',
                                                            padding: '10px'
                                                        }}>
                                                            <Checkbox style={{ color: "white" }} />
                                                            <IconButton>
                                                                <MoreVertIcon style={{ color: 'white', marginTop: '-2' }} />
                                                            </IconButton>
                                                        </Box>

                                                    </Box>

                                                    <CardContent>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                                                                <Typography style={{ fontSize: 14, fontFamily: "Poppins", fontWeight: 400 }}>
                                                                    Cake
                                                                </Typography>

                                                                <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                                    <ChatBubbleOutlineIcon style={{ color: '#78716C' }} />
                                                                    <Typography style={{ color: '#78716C', marginLeft: '1px' }}>2</Typography>
                                                                    <FavoriteIcon style={{ color: 'red', marginLeft: '3px' }} />
                                                                    <Typography style={{ color: '#78716C' }}>3</Typography>
                                                                    <MoreVertIcon />
                                                                </Box>
                                                            </Box>

                                                        </Box>

                                                        <Box style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                                                            <img src={avatar} style={{ marginLeft: '-15px', height: '26px', width: '26px' }} />
                                                            <img src={avatar} style={{ height: '26px', width: '26px' }} />
                                                            <Typography style={{ fontSize: 12, fontWeight: 400, fontFamily: "Poppins", color: "#5D6063", marginLeft: 10 }}>
                                                                Kate & me
                                                            </Typography>
                                                        </Box>


                                                    </CardContent>
                                                </Card>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                    </Box>

                </Box>



            </UserProfileViewingStyle>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const UserProfileViewingStyle = styled("div")({
    display: 'flex',
    flexDirection: 'row',
    height: '1000vh',
    "& .MainContent": {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '150vh',
        marginBottom: 700
    },
    "& .backIcon": {
        width: 32,
        height: 32,
        cursor: "Pointer"
    },
    "& .NameWithBackIconBox": {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 100,
        marginLeft: 30,
        gap: 20,
    },
    "& .SharingText": {
        fontSize: 30,
        fontWeight: 700,
        fontFamily: "Poppins",
        color: "#1C2110"
    },
    "& .nameText": {
        fontSize: 36,
        fontFamilt: "Poppins",
        fontWeight: '700',
        color: '#1C2110'
    },
    "& .nameTextRelation": {
        fontSize: 20,
        fontFamilt: "Poppins",
        fontWeight: '700',
        color: "#557C29"
    },
    "& .filesText": {
        fontFamily: "Poppins",
        fontWeight: "700",
        fontSize: "12px",
        color: "#A8A29E",
        marginTop: 10
    },
    "& .boxesStyle": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 12
    },
    "& .filterText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: '26px',
        color: "#1C2110",
    },
    "& .filterMainBox": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px'
    },
    "& .filterSubBox": {
        display: 'flex',
    },
    "& .filterBox": {
        display: 'flex',
        alignItems: 'center',
        color: '#5D6063',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: '24px',
        textTransform: "none"
    },
    "& .filterBoxText": {
        color: '#5D6063',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: '24px',
        textTransform: "none",
    },
    "& .expandBoxs": {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#1C2110',
        width: '100%',
        padding: '10px'
    },
    "& .expandBtnText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: '26px',
        color: '#1C2110',
        textTransform: "none"
    },
    "& .photoExpandBox": {
        backgroundImage: `url(${dummyImage})`,
        backgroundRepeat: 'no-repeat',
        width: '244px',
        height: '224px'
    },
    "& .recipesExpandBox": {
        backgroundImage: `url(${choclateCake})`,
        backgroundRepeat: 'no-repeat',
        width: '244px',
        height: '224px'
    },
    favIconStyle:
    {
        color: 'red',
        marginLeft: '3px'
    }
});

// Customizable Area End
