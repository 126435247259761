import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles"
import { Logo, backArrow, backImage, notValidate, validate } from "./assets";
import { ErrorMessage, Formik } from "formik";
import { Alert } from '@material-ui/lab';

// Customizable Area End

import SignuploginController, {
  Props,
} from "./SignuploginController";

export default class Signuplogin extends SignuploginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderValidatePassword = (password: string) => (
    <div className="validatePasswordWrapper">
      {this.validatePassword.map((item, index) => {
        const isValid = item.regex.test(password);
        return (
          <div
            key={index}
            className="validatePasswordItem"
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: "Poppins",
              fontWeight: 400,
              fontSize: '14px'
            }}
          >
            {isValid ? (
              <img
                src={validate}
                alt="checkIcon"
                className="check-icon"
                style={{ marginRight: "8px", height: "14px", width: "14px" }}
              />
            ) : (
              <img
                src={notValidate}
                alt="checkIcon"
                className="check-icon"
                style={{ marginRight: "8px", height: "14px", width: "14px" }}
              />
            )}
            {item.text}
          </div>
        );
      })}
    </div>
  );

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <SignupLoginStyle>
        <Button data-test-id="backArraowClick" className="backButton" onClick={this.backArraowClick}>
          <img src={backArrow} alt="Back" />
        </Button>
        <Box className="mainContainer">
          <Box className="formContainer">
            <Box className="imageLogo">
              <img style={{ height: '47px', width: '95px' }} src={Logo} alt="Logo" />
            </Box>
            <Formik
              enableReinitialize
              data-test-id="formikSignupForm"
              initialValues={{
                fullName: this.state.fullName,
                email: this.state.email,
                password: this.state.password,
                agreeTerms: this.state.agreeTerms,
                isNewsChecked: this.state.isNewsChecked
              }}
              validationSchema={this.signUpSchema}
              onSubmit={values => {
                if (this.checkValidatePassword(values.password)) {
                  this.submitValidateAccount(values.fullName, values.email, values.password, values.isNewsChecked);
                }
              }}
            >
              {({ errors, touched, handleSubmit, handleChange, values, isSubmitting}) => (
                <form className="formContainer" onSubmit={handleSubmit}>
                  {this.state.apiError && (
                    <Alert
                      className="alertBox"
                      severity="error"
                      icon={false}
                      style=
                      {{
                        marginTop: '32px'
                      }}
                    >
                      {this.state.apiError}
                    </Alert>
                  )}
                  {!this.state.agreeTerms && (
                    <Alert
                      className="alertBox"
                      severity="error"
                      icon={false}
                      style=
                      {{
                        display: touched.agreeTerms && errors.agreeTerms && !values.agreeTerms ? 'block' : 'none',
                        marginTop: '32px'
                      }}
                    >
                      You have to agree with Privacy Policy and Terms and Conditions to Sign up.
                    </Alert>
                  )}
                  <Typography className="signupTitle">Sign up to MyKinships</Typography>
                  <Typography className="signupSubTitle">Start your journey with us.</Typography>
                  <Typography className="titleText">Full name</Typography>
                  <TextField
                    data-test-id="nameField"
                    className="placeholderText"
                    value={this.state.fullName}
                    onChange={(e) => this.setState({ fullName: e.target.value })}
                    autoComplete="off"
                    name="fullName"
                    placeholder="Your full name"
                    variant="outlined"
                    fullWidth
                  />
                  <ErrorMessage name="fullName" component="div" className="errorMessage" />
                  <Typography className="titleText">E-mail</Typography>
                  <TextField
                    data-test-id="emailField"
                    className="placeholderText"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    autoComplete="off"
                    name="email"
                    placeholder="Your e-mail"
                    variant="outlined"
                    fullWidth
                  />
                  <ErrorMessage name="email" component="div" className="errorMessage" />
                  <Typography className="titleText">Password</Typography>
                  <TextField
                    className="placeholderText"
                    data-test-id="passwordField"
                    value={this.state.password}
                    onChange={(e) => this.setState({ password: e.target.value })}
                    autoComplete="off"
                    fullWidth
                    name="password"
                    placeholder="Your password"
                    variant="outlined"
                    type={!this.state.showPassword ? "password" : "text"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            id="passwordConfirmationInput"
                            onClick={this.handleClickShowPassword}
                            edge="end"
                            data-test-id="showPasswordIconButton"
                          >
                            {this.renderIconShowPassword(
                              !this.state.showPassword
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage name="password" component="div" className="errorMessage" />
                  {values.password.trim().length > 0 &&
                    this.renderValidatePassword(values.password)}
                  <Box className="conditionContainer">
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-test-id="handlePolicyCheck"
                          checked={this.state.agreeTerms || localStorage.getItem("agreeTerms") === "true"}
                          onChange={(e) => {
                            this.handlePolicyCheckbox(e, values)
                            handleChange(e)
                          }}
                          name="agreeTerms"
                          style={{ color: this.state.agreeTerms || localStorage.getItem("agreeTerms") === "true" ? "#557C29" : "#5D6063" }}
                        />
                      }
                      label={
                        <Typography>
                          <span style={{ color: "#1C2110", fontWeight: 400, fontSize: "14px", lineHeight: "22px", fontStyle: "Poppins" }}>I agree with </span>
                          <Button onClick={() => this.handlePolicyClick(values)} className="policyTitle" data-test-id="policyBotton">Privacy Policy and</Button>
                        </Typography>
                      }
                    />
                    <Button data-test-id="policyBotton" onClick={() => this.handlePolicyClick(values)} className="termsTitle">Terms and Conditions</Button>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.isNewsChecked}
                        style={{ color: this.state.isNewsChecked ? "#557C29" : "#5D6063" }}
                        onChange={this.handleCheckbox}
                      />
                    }
                    label={
                      <Typography className="subscribeCheckBoxTitle" style={{ cursor: 'default' }}>Subscribe to our newsletter.</Typography>
                    }
                  />
                  <Button type="submit" data-test-id="signupButton" className="signupBtn" variant="contained" color="primary" fullWidth disabled={isSubmitting}>
                    Sign up
                  </Button>

                  <Box className="accountcontainer">
                    <Typography className="accountTextTitle">Already have an account?</Typography>
                    <Button onClick={this.handleNavigateLogin} data-test-id="loginButton" className="loginBtn">Log in</Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </SignupLoginStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SignupLoginStyle = styled("div")({
  height: "100vh",
  width: '100%',
  backgroundImage: `url(${backImage})`,
  backgroundColor: "#305328",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",

  "& .backButton": {
    position: 'fixed',
    top: "20px",
    zIndex: 9999,
    left: '20px',
  },

  "& .mainContainer": {
    overflowY: "auto",
    maxHeight: "calc(100vh - 40px)",
    backgroundColor: "white",
    borderRadius: "20px",
    width: '440px',
    padding: "40px",
  },
  "& .formContainer": {
    width: '100%',
  },
  "& .imageLogo": {
    padding: "0px, 0px, 0px, 40px",
  },
  "& .signupTitle": {
    marginTop: "32px",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
    letterSpacing: "-0.5%",
    color: "#1C2110"
  },
  "& .signupSubTitle": {
    marginTop: '8px',
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#202020",
    marginBottom: '20px'
  },
  "& .errorMessage": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "18px",
    color: '#DC2626',
  },
  "& .signupBtn": {
    backgroundColor: '#8CA74B',
    height: "56px",
    borderRadius: "8px",
    padding: "16px",
    Gap: "8px",
    marginTop: "24px",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    "& .MuiButton-label": {
      textTransform: "none",
      fontWeight: 700,
      fontSize: "16px",
    },
  },
  "& .accountcontainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "16px"
  },
  "& .titleText": {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110",
    marginTop: '16px',
    marginBottom: '4px'
  },
  "& .placeholderText": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Poppins",
    color: "#94A3B8"
  },
  "& .conditionContainer": {
    marginTop: '28px'
  },
  "& .subscribeCheckBoxTitle": {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    fontStyle: "Poppins",
    color: "#1C2110"
  },
  "& .termsTitle": {
    color: "#557C29",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    fontStyle: "Poppins",
    marginLeft: '23px',
    marginTop: '-15px',
    "& .MuiButton-label": {
      textTransform: "none",
      fontWeight: 700,
      fontSize: "14px",
      fontStyle: "Poppins",
    },
  },
  "& .policyTitle": {
    marginLeft: '-5px',
    color: "#557C29",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    fontStyle: "Poppins",
    "& .MuiButton-label": {
      textTransform: "none",
      fontWeight: 700,
      fontSize: "14px",
      fontStyle: "Poppins",
    },
  },
  "& .accountTextTitle": {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C2110"
  },
  "& .loginBtn": {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#557C29",
    fontStyle: "Poppins",
    "& .MuiButton-label": {
      textTransform: "none",
    },
  },
  "& .validatePasswordWrapper": {
    marginTop: "12px"
  },
  "& .alertBox": {
    borderRadius: '4px',
    border: '0px, 0px, 0px, 4px',
    borderLeft: '4px solid #DC2626',
    padding: "12px, 16px, 12px, 16px",
    fontSize: "12px",
    foneFamily: "Poppins",
    fontWeight: 400,
    color:'#DC2626'
  }
});
// Customizable Area End
