import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  showSucessModal: boolean;
  showErrorModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewsLetterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  subscribeNewsLetteApiId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email: '',
      showSucessModal: false,
      showErrorModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (
      apiRequestCallId &&
      responseJson &&
      apiRequestCallId === this.subscribeNewsLetteApiId
    ) {
      if (responseJson?.errors && responseJson?.errors?.length !== 0) {
        this.setState({ email: '', showErrorModal: true })
      }
      else {
        this.setState({ showSucessModal: true });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  newsLetterSchema = Yup.object({
    email: Yup.string()
      .email("Invalid e-mail")
      .matches(/^\S+$/, 'Email cannot have blank spaces.')
      .required("Please enter your email"),
  });

  navigateToPage = (targetPage: any) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), targetPage);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  onLoginClick = () => {
    this.navigateToPage("Login");
  }

  errorModalClose = () => {
    this.setState({ showErrorModal: false, email: '' })
  }

  sucessModalClose = () => {
    this.setState({ showSucessModal: false , email: ''})
  }

  onRegisterClick = () => {
    this.navigateToPage("Signuplogin");
  }

  navigateBack = () => {
    this.navigateToPage("LandingPage");
  }

  subscribeNewsLetter = (email: string) => {
    let token = localStorage.getItem("token");
    this.setState({
      email: email
    })
    const header = { token: token };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.subscribeNewsLetteApiId = requestMsg.messageId;
    requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/subscribe_to_newsletter?email=${email}`);
    requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
    runEngine.sendMessage(requestMsg.id, requestMsg);
    return true
  }
  // Customizable Area End
}