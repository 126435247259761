import React from "react";

import {
    Box,
    Button,
    Typography,
    TextField,
    // Customizable Area Start
    InputAdornment,
    IconButton,
    DialogTitle,
    Dialog,
    Divider,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles";
import CloseIcon from '@material-ui/icons/Close';
import { Logo, backArrow, backImageReset, checkImg, disableChecked, notValidate, validate } from "./assets";
import { ErrorMessage, Formik } from "formik";
// Customizable Area End

import ResetPasswordController, {
    Props,
} from "./ResetPasswordController.web";

export default class ResetPassword extends ResetPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderValidatePassword = (password: string, confirmPassword: string) => {
        const isPasswordEmpty = password.trim().length === 0;
        const passwordsMatch = password === confirmPassword;

        return (
            <div className="validatePasswordWrapper">
                {this.validatePassword.map((item, index) => {
                    const isValid = item.regex.test(password);
                    return (
                        <div key={index} className="validatePasswordItem" >
                            {isPasswordEmpty ? (
                                <img
                                    src={disableChecked}
                                    alt="checkIcon"
                                    className="check-icon"
                                    style={{ marginRight: "8px", height: "14px", width: "14px" }}
                                />
                            ) : (
                                <img
                                    src={isValid ? validate : notValidate}
                                    alt="checkIcon"
                                    className="check-icon"
                                    style={{
                                        marginRight: "8px",
                                        height: isValid ? "15px" : "14px",
                                        width: isValid ? "15px" : "14px",
                                    }}
                                />
                            )}
                            {item.text}
                        </div>
                    );
                })}
                {isPasswordEmpty ? (
                    <div className="validatePasswordItem"  >
                        <img
                            src={disableChecked}
                            alt="checkIcon"
                            className="check-icon"
                            style={{ marginRight: "8px", height: "14px", width: "14px" }}
                        />
                        Passwords match
                    </div>
                ) : (
                    passwordsMatch ? (
                        <div className="validatePasswordItem" >
                            <img
                                src={validate}
                                alt="checkIcon"
                                className="check-icon"
                                style={{ marginRight: "8px", height: "15px", width: "15px" }}
                            />
                            Passwords match
                        </div>
                    ) : (
                        <div className="validatePasswordItem"  >
                            <img
                                src={notValidate}
                                alt="checkIcon"
                                className="check-icon"
                                style={{ marginRight: "8px", height: "14px", width: "14px" }}
                            />
                            Passwords match
                        </div>
                    )
                )}
            </div>
        );
    };

    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ResetPasswordStyle>
                <Button data-test-id="backArraowBotton" className="backArrowClick" onClick={this.backArraowClick}>
                    <img src={backArrow} alt="Back" />
                </Button>
                <Box className="mainDiv">
                    <Box className="formContainer">
                        <Box className="imageLogo">
                            <img style={{ height: '70px' }} src={Logo} alt="Logo" />
                        </Box>
                        <Formik
                            data-test-id="formikForm"
                            initialValues={{
                                password: this.state.resetPasswordData.password || "",
                                confirmPassword: this.state.resetPasswordData.confirmPassword || "",
                            }}
                            validationSchema={this.resetPasswordSchema}
                            onSubmit={async (values, { setSubmitting }) => {
                                if (this.checkValidatePassword(values.password)) {
                                    this.submitValidateAccount(values.password, values.confirmPassword);
                                    setSubmitting(false);
                                }
                            }}>
                            {({ handleSubmit, handleChange, values }) => (
                                <form className="formContainer" onSubmit={handleSubmit}>
                                    <Typography className="mainTitles">New password</Typography>
                                    <Typography className="forgotTextTitle">Enter a new password for a
                                        <br />
                                        {localStorage.getItem("email")}
                                    </Typography>
                                    <Typography className="FieldName">New Password</Typography>
                                    <TextField
                                        data-test-id="passwordField"
                                        className="placeholderTitle"
                                        value={values.password}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        fullWidth
                                        name="password"
                                        placeholder="Your new password"
                                        variant="outlined"
                                        type={!this.state.showPassword ? "password" : "text"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        data-test-id="showPasswordIconButton"
                                                        id="passwordConfirmationInput"
                                                        onClick={() => this.handleClickShowPassword("password")}
                                                        edge="end"
                                                    >
                                                        {this.renderIconShowPassword(!this.state.showPassword)}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <ErrorMessage name="password" component="div" className="errorMessages" />
                                    <Typography className="FieldName">Confirm new Password</Typography>
                                    <TextField
                                        data-test-id="confirmPasswordField"
                                        className="placeholderTitle"
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        autoComplete="off"
                                        fullWidth
                                        name="confirmPassword"
                                        placeholder="Confirm your new password"
                                        variant="outlined"
                                        type={!this.state.showConfirmPassword ? "password" : "text"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        id="passwordConfirmationInput"
                                                        onClick={() => this.handleClickShowPassword("confirmPassword")}
                                                        edge="end"
                                                        data-test-id="showPasswordIconButton"
                                                    >
                                                        {this.renderIconShowConfirmPassword(!this.state.showConfirmPassword)}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <ErrorMessage name="confirmPassword" component="div" className="errorMessages" />
                                    {this.renderValidatePassword(values.password, values.confirmPassword)}
                                    <Button type="submit" data-test-id="recoveryLinkBtn" className="recoveryLinkBtn" variant="contained" color="primary" fullWidth>
                                        Reset password
                                    </Button>
                                    <Box className="accountDiv">
                                        <Typography className="accountTitle">Back to</Typography>
                                        <Button onClick={this.backArraowClick} data-test-id="loginBtn" className="loginBottons">Log in</Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>

                <Dialog open={this.state.showModal} onClose={this.handleCloseModel} className="dialogContainer" PaperProps={{
                    style: {
                        position: 'relative',
                        height: 'auto',
                        width: '680px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderRadius: 8,
                        borderBottomRightRadius: 50,
                    }
                }} >
                    <DialogTitle>
                        <Typography style={{ fontSize: 20, fontWeight: 700, fontFamily: 'Poppins', color: '#1C2110' }}>Change Password</Typography>
                        <IconButton onClick={this.handleCloseModel} data-test-id='closeIcon' style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#666',
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogBoxContent style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Box style={{
                                textAlign: 'center',
                                backgroundColor: '#34D399',
                                borderRadius: '80px',
                                height: '130px',
                                width: '130px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '25px'
                            }}>
                                <img className='checkImg' src={checkImg} />
                            </Box>
                        </div>
                        <Box style={{ textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px', marginTop: '40px' }}>
                            <Typography style={{
                                fontFamily: "Poppins",
                                fontWeight: 700,
                                fontSize: "20px",
                                lineHeight: "28px",
                                color: "#1C2110",
                                alignItems: 'center'
                            }} >Password changed Successfully!!</Typography>
                        </Box>
                    </DialogBoxContent>
                    <Divider />
                </Dialog>


            </ResetPasswordStyle>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const DialogBoxContent = styled(Box)(() => ({
    display: 'flex',
    height: "275px",
    padding: "20px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: 'relative',
    "@media(max-width: 768px)": {
        padding: "10px",
        height: "240px !important",
    },
}));

const ResetPasswordStyle = styled("div")({
    height: "100vh",
    backgroundImage: `url(${backImageReset})`,
    width: "100%",
    backgroundColor: "#305328",
    backgroundRepeat: "no-repeat",
    justifyContent: 'center',
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    position: 'relative',

    "& .backArrowClick": {
        top: "20px",
        position: 'fixed',
        zIndex: 9999,
        left: "20px",
    },
    "& .mainDiv": {
        width: "440px",
        borderRadius: "20px",
        padding: "40px",
        overflow: "hidden",
        backgroundColor: "white",
    },
    "& .scrollableContent": {
        maxHeight: "calc(100vh - 160px)",
        overflowY: 'auto',
    },
    "& .formContainer": {
        width: "100%",
    },
    "& .imageLogo": {
        padding: '0px, 0px, 0px, 40px',
    },
    "& .validatePasswordWrapper": {
        marginTop: "12px"
    },
    "& .validatePasswordItem": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: "18px",
        display: 'flex',
        alignItems: 'center'
    },
    "& .errorMessages": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "18px",
        color: '#DC2626',
        marginTop: '2px'
    },
    "& .mainTitles": {
        marginTop: "32px",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "-0.5%",
        color: "#1C2110"
    },
    "& .forgotSubTitle": {
        marginTop: '8px',
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginBottom: '16px'
    },
    "& .forgotTextTitle": {
        marginTop: '8px',
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#202020",
        marginBottom: '30px'
    },
    "& .recoveryLinkBtn": {
        color: "#FFFFFF",
        backgroundColor: '#8CA74B',
        borderRadius: "8px",
        height: "56px",
        Gap: "8px",
        padding: "16px",
        fontWeight: 700,
        marginTop: "24px",
        lineHeight: "24px",
        fontSize: "16px",
        '& .MuiButton-label': {
            fontSize: "16px",
            fontWeight: 700,
            textTransform: "none",
            fontFamily: "Poppins",
        }
    },
    "& .accountDiv": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: '16px'
    },
    "& .FieldName": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginTop: "16px",
        marginBottom: "4px"
    },
    "& .placeholderTitle": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins",
        color: "#94A3B8"
    },
    "& .accountTitle": {
        fontWeight: 400,
        lineHeight: "24px",
        fontSize: "16px",
        color: "#1C2110",
        fontFamily: "Poppins",
    },
    "& .loginBottons": {
        fontStyle: "Poppins",
        fontWeight: 700,
        lineHeight: "24px",
        fontSize: "16px",
        color: "#557C29",
        '& .MuiButton-label': {
            fontFamily: "Poppins",
            fontWeight: 700,
            textTransform: "none"
        }
    },
});
// Customizable Area End