// Customizable Area Start
import React from "react";
import BlogPostsManagementController, { Props } from "./BlogPostsManagementController";
import { backIcon, firstImage, leftIcon, rightIcon } from "./assets";
import { Box, Card, CardActionArea, CardContent, CardMedia, IconButton, InputBase, Paper, Typography } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Header from "../../../components/src/Header.web";
import Footer from "../../navigationmenu/src/Footer.web";
import DOMPurify from 'dompurify';
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default class BlogpostsDetails extends BlogPostsManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    const { currentIndex, showBlogs } = this.state;
    const visibleCards = this.state.blogDetails.slice(currentIndex, currentIndex + 3);

    return (
      // Customizable Area Start
      <Box>
        {
            this.state.authToken ? (
              <HeaderDropdown
                data-test-id="headerComponenttestid"
                navigation={undefined}
                id={""}
              />
            ) : (
              <Header onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} getHeaderData={() => {}} />
            )
            }
        <Box sx={webStyle.container}>
          <Box sx={webStyle.header}>
            <img src={backIcon} style={webStyle.backIcon} onClick={this.handleBackClick} data-test-id="backIconTest" />
            <Paper
              component="form"
              style={webStyle.searchIconStyle}
              onSubmit={this.handleSubmit}
            >
              <IconButton type="button" style={{ color: "#8CA74B", padding: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <InputBase
                data-test-id="inputBoxId"
                style={{ marginLeft: 1, flex: 1, color: "rgb(103, 147, 8)", fontSize: 18, fontFamily: "Poppins",fontWeight: 500 }}
                value={this.state.searchText}
                placeholder="Search"
                onChange={(e) => this.handleChange(e)}
                inputProps={{ className: "custom-input" }}

              />
              {this.state.searchText && (
                <IconButton data-test-id="clearBtn" onClick={this.handleClear} style={{ padding: "10px", color: "#8CA74B" }} aria-label="clear">
                  <ClearIcon />
                </IconButton>
              )}


            </Paper>
            <div style={{ fontFamily: "sans-serif" }}>
              <div
                style={{
                  margin: "0 auto",
                  zIndex: 10000,
                  width: "188px",
                  marginTop: 92,
                  height: 55,
                  position: "relative",
                  marginLeft: 30,
                }}
              >
                <div
                  onClick={this.handleToggle.bind(this)}
                  style={{
                    ...webStyle.dropdownStyle,
                    color: "#8CA74B"
                  }}
                >
                  {this.state.selected}
                  {this.state.isActive ? (
                    <KeyboardArrowUpIcon
                      style={{
                        transition: 'transform 0.3s ease',
                        color: '#8CA74B',
                      }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      style={{
                        transition: 'transform 0.3s ease',
                        color: '#8CA74B',
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    position: "absolute",
                    boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
                    width: "100%",
                    left: 0,
                    borderRadius: 8,
                    display: this.state.isActive ? "block" : "none"
                  }}
                >
                  {[
                    { name: "All categories", id: "all" }, 
                    ...this.state.categoriesData 
                  ].map((option, index) => (
                    <div
                    key={index}
                    className="item"
                    style={{
                      padding: 10,
                      color: "#8CA74B",
                      cursor: "pointer",
                      backgroundColor: this.getBackgroundColor(option.name),
                      borderRadius: 4
                    }}
                    onClick={this.handleOptionSelect.bind(this, option)}
                  >
                    {option.name}
                  </div>
                  ))}
                </div>
              </div>
            </div>
          </Box>
          <Box display="inline-block" position="relative" mt={2}>
            <Typography
              style={{
                ...webStyle.blogText,
                width: 20,
                borderBottom: '2px solid #78716C',
                display: 'inline-block',
                paddingBottom: '10px',
              }}
            >
              BLOG
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                width: '6%',
                borderBottom: '2px solid #78716C',
                left: 10,
                zIndex: -1,
              }}
            />
          </Box>
          <Box sx={webStyle.detailsPage}>
            <Typography style={webStyle.exploreInspire}>
              {showBlogs.title}
            </Typography>
          </Box>
          <Typography style={{ fontFamily: 'Poppins',  fontSize: 18, fontWeight: 400 }}>
            {showBlogs.author}
          </Typography>
          <Box style={{ display: 'flex', flexDirection: 'row', marginTop: 15 }}>
            <Box
              style={{
                width: 115,
                backgroundColor: "#E8FFB1",
                borderRadius: 28
              }}
            >
              <Typography
                style={{ color: "#557C29", textAlign: "center",  padding: 5, fontSize: 12 }}
              >

                {showBlogs.category_name}
              </Typography>
            </Box>
            
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',marginTop: 30 }}>
            <img src={showBlogs.attachment_urls?.[0]?.url} style={{ width: "598px", height: "400px", borderRadius: "16px" }} />
          
          
            <Box style={{
              borderTop: '2px solid #8CA74B',
              borderBottom: '2px solid #8CA74B',
              borderRight: '2px solid #8CA74B',
              height: '300px',
              marginLeft: '-2px',
              display: 'flex',
              padding: '25px',
              alignItems: 'center',
              borderRadius: '0 16px 16px 0'
            }}>
              <Typography style={{
                fontWeight: 700,
                fontFamily: 'Poppins',
                fontSize: 17,
                width: "362px"
              }}>
                {showBlogs.content}
              </Typography>
            </Box>
          </Box>

          <Box style={{
            justifyContent: 'center',
            alignSelf: 'center'
          }}>
            <Typography
              style={{ fontSize: 18, fontWeight: 400, fontFamily: 'Poppins', marginTop: 20 }}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(showBlogs.description) }}
            />
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 20, position: 'relative' }}>
            <Typography style={{ fontSize: 30, fontWeight: 700, fontFamily: 'Poppins' }}>You may also like</Typography>
            <Box style={{ position: 'absolute', bottom: -10, width: 65, height: 2, backgroundColor: '#64748B' }} />
          </Box>
          <Box style={{ display: 'flex', marginTop: 50, flexDirection: 'row', flexWrap: 'wrap', gap: '22px', width: '1160px', marginLeft: 50, alignSelf: 'center', marginBottom: '100px' }}>
            <img src={leftIcon} style={{ width: '32px', height: '32px', display: 'flex', alignSelf: 'center', cursor: 'pointer' }} onClick={this.handleLeftClick} data-test-id="leftIcon" />
            {visibleCards.map((data: any, index: any) => (
              <Card key={index} style={{ maxWidth: 316, height: 490 }} onClick={() => this.gotoDestinationScreen(data)}>
                <CardActionArea>
                  <Box
                    style={{
                      backgroundColor: "#E8FFB1",
                      borderRadius: 10,
                      width: 115,
                      marginLeft: 20
                    }}
                  >
                    <Typography
                      style={{ color: "#557C29", textAlign: "center",  marginTop: 20, fontSize: 12}}
                    >
                      {data.category_name}
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <CardMedia
                      component="img"
                      style={{ borderRadius: 8,  marginTop: 20, width: 270, height: 200 }}
                      image={data.attachment_urls[0].url}
                    />
                  </Box>
                  <CardContent>
                  <Typography
                        style={webStyle.titleTextTypo as any}
                      >
                        <span
                          style={webStyle.titleText as any}
                        ></span>
                        {data.title}
                      </Typography>
                      <Typography
                        style={webStyle.truncateStyle}
                      >
                        {this.truncateText(data.content, 60)}
                      </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
            <img src={rightIcon} style={{ height: '32px', width: '32px',display: 'flex', alignSelf: 'center', cursor: 'pointer' }} onClick={this.handleRightClick} data-test-id="rightIcon" />
          </Box>

        </Box >
        <Footer navigation={this.props.navigation} id={""} />
      </Box>
      // Customizable Area End
    );
  }
}

const webStyle = {
  backIcon: {
    width: 32,
    height: 32,
    marginTop: 92,
    position: 'absolute' as 'absolute',
    left: -120,
    cursor: "Pointer"
  },
  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    maxWidth: '1016px',
    margin: 'auto'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  searchIconStyle: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: 780,
    height: 44,
    marginTop: 108,
    borderRadius: "40px",
    border: "1px solid #8CA74B",
  },
  dropdownStyle: {
    cursor: "pointer",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 14,
    borderRadius: 8,
    fontWeight: 500,
    border: `1px solid #8CA74B`,
    fontSize: 16
  },
  blogText: {
    color: '#78716C',
    fontWeight: 400,
    fontSize: 20,
    fontFamily: 'Poppins',
    marginLeft: "1%"
  },
  exploreInspire: {
    color: '#1C2110',
    fontFamily: 'Poppins',
    marginTop: 30,
    fontSize: 48,
    fontWeight: 700,
    // marginLeft: 'auto'
  },
  detailsPage: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  truncateStyle:{
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: 400,
    marginTop: 30,
    marginLeft: 25,
    width: 230
  },
  titleText:{
    height: "100%",
    position: "absolute",
    top: 0,
    borderLeft: "7px solid #557C29",
    left: -15,
  },
  titleTextTypo:{
    fontSize: 17,
    position: "relative",
    fontFamily: "Poppins",
    fontWeight: 700,
    marginLeft: 15,
    maxWidth:"260px",
    width:"100%",
    height:52
  }
};
// Customizable Area End
