import React from "react";

import {
    Box,
    Button,
    Typography,
    TextField,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles"
import { Logo, backArrow, backImage } from "./assets";
import { ErrorMessage, Formik } from "formik";
// Customizable Area End

import ForgotPasswordController, {
    Props,
} from "./ForgotPasswordController.web";
import { Alert } from "@material-ui/lab";

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ForgotPasswordStyle>
                <Button data-test-id="backArraowBtn" className="backBtn" onClick={this.backArraowBtn}>
                    <img src={backArrow} alt="Back" />
                </Button>
                <Box className="mainContainerDiv">
                    <Box className="formContainerDiv">
                        <Box className="imageLogo">
                            <img style={{ height: '70px' }} src={Logo} alt="Logo" />
                        </Box>
                        <Formik
                            data-test-id="formikForm"
                            initialValues={{
                                email: "",
                            }}
                            validationSchema={this.forgotPasswordSchema}
                            onSubmit={values => {
                                this.submitValidateAccount(values.email);
                            }}
                        >
                            {({ handleSubmit, handleChange, values }) => (
                                <form className="formContainerDiv" onSubmit={handleSubmit}>
                                    {this.state.apiError && (
                                        <Alert
                                            className="alertContainer"
                                            severity="error"
                                            icon={false}
                                            style=
                                            {{
                                                display: 'block',
                                                marginTop: '32px'
                                            }}
                                        >
                                            {this.state.apiError}
                                        </Alert>
                                    )}
                                    <Typography className="mainFormTitle">Password recovery</Typography>
                                    {!this.state.recoveryLinkSent ? (
                                        <>
                                            <Typography className="forgotTitle">Forgot password?</Typography>
                                            <Typography className="forgotTextTitles">No worries, we’ll send a recovery link to
                                                <br />
                                                your e-mail.</Typography>
                                            <Typography className="FieldText">E-mail</Typography>
                                            <TextField
                                                type="email"
                                                data-test-id="emailTextField"
                                                className="placeholderTitle"
                                                value={values.email}
                                                onChange={(e) => {
                                                    this.setState({ apiError: null });
                                                    handleChange(e);
                                                }}
                                                autoComplete="off"
                                                name="email"
                                                placeholder="Your e-mail"
                                                variant="outlined"
                                                fullWidth
                                            />
                                            <ErrorMessage name="email" component="div" className="errorMessages" />
                                            <Button type="submit" data-test-id="signupButton" className="recoveryLinkBotton" variant="contained" color="primary" fullWidth >
                                                Send a recovery link
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Typography className="forgotTitle">Check your e-mail</Typography>
                                            <Typography className="forgotTextTitles">We have sent a password reset link to {values.email}.</Typography>
                                            <Button type="submit" data-test-id="resetEmailBtn" className="recoveryLinkBotton" variant="contained" color="primary" fullWidth >
                                                Resend e-mail
                                            </Button>
                                        </>
                                    )}
                                    < Box className="accountContainer">
                                        <Typography className="accountTextTitle">Back to</Typography>
                                        <Button onClick={this.handleNavigateLoginBtn} data-test-id="loginButton" className="loginBotton">Log in</Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                </Box>
            </ForgotPasswordStyle>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const ForgotPasswordStyle = styled("div")({
    height: "100vh",
    overflow: "hidden",
    backgroundImage: `url(${backImage})`,
    width: "100%",
    display: "flex",
    backgroundColor: "#305328",
    alignItems: "center",
    position: "relative",
    justifyContent: "center",

    "& .backBtn": {
        position: "fixed",
        top: "20px",
        left: "20px",
        zIndex: 9999,
    },
    "& .mainContainerDiv": {
        backgroundColor: "white",
        borderRadius: "20px",
        width: "440px",
        padding: '40px',
        overflow: "hidden",
    },
    "& .scrollableContent": {
        maxHeight: "calc(100vh - 160px)",
        overflowY: "auto",
    },
    "& .formContainerDiv": {
        width: "100%"
    },
    "& .imageLogo": {
        padding: "0px, 0px, 0px, 40px",
    },
    "& .mainFormTitle": {
        color: "#1C2110",
        marginTop: "32px",
        marginBottom: "32px",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "-0.5%",
    },
    "& .alertContainer": {
        borderRadius: '4px',
        border: '0px, 0px, 0px, 4px',
        borderLeft: '4px solid #DC2626',
        padding: "12px, 16px, 12px, 16px",
        fontSize: "12px",
        foneFamily: "Poppins",
        fontWeight: 400,
    },
    "& .errorMessages": {
        fontFamily: "Poppins",
        fontWeight: 400,
        lineHeight: "18px",
        fontSize: "13px",
        color: '#DC2626',
        marginTop: '2px'
    },
    "& .forgotTitle": {
        marginBottom: "16px",
        marginTop: "8px",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
    },
    "& .forgotTextTitles": {
        fontFamily: "Poppins",
        marginTop: '8px',
        fontSize: "16px",
        fontWeight: 400,
        color: "#202020",
        lineHeight: "24px",
    },
    "& .recoveryLinkBotton": {
        borderRadius: "8px",
        backgroundColor: '#8CA74B',
        height: "56px",
        Gap: "8px",
        padding: "16px",
        fontWeight: 700,
        marginTop: "32px",
        lineHeight: "24px",
        fontSize: "16px",
        '& .MuiButton-label': {
            textTransform: "none",
            fontWeight: 700,
            fontFamily: "Poppins",
        }
    },
    "& .accountContainer": {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        marginTop: "16px"
    },
    "& .FieldText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginTop: '16px',
        marginBottom: '4px'
    },
    "& .placeholderTitle": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins",
        color: "#94A3B8"
    },
    "& .accountTextTitle": {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Poppins",
        color: "#1C2110"
    },
    "& .loginBotton": {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#557C29",
        fontStyle: "Poppins",
        '& .MuiButton-label': {
            fontWeight: 700,
            fontFamily: "Poppins",
            textTransform: "none"
        }
    },
});
// Customizable Area End