// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { share, uploadImage, organized, cloudDone } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;

  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start



interface Plan {
  id: number;
  name: string;
  details: string;
  storage: number;
  price: string;
  storage_unit: string;
  stripe_product_id: string;
  stripe_price_id: string;
  created_at: string;
  updated_at: string;
}


// Customizable Area End

interface S {
  recipeFaq: boolean;
  subscriptionData: Plan[];
  landingData:any
  // Customizable Area End
}

interface SS {
  id: string | number;
  // Customizable Area Start
  // Customizable Area End
}


export default class BlogPostsManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  landingPageApiCallID: any;
  getAllSubscriptionApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      recipeFaq: false,
      subscriptionData: [],
      landingData:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        if(apiRequestCallId === this.landingPageApiCallID){
          console.log("response 338 photos data", responseJson.landing_pages[0]);
          this.setState({landingData: responseJson.landing_pages})
          
        }
      }
    }
   
  
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
let activetab=sessionStorage.getItem('tab')
 this.landingPageFilterAPi(activetab)
  }


onLoginClick = () => {
  this.props.navigation.navigate("Login")
}

onRegisterClick = () => {
  this.props.navigation.navigate("Signuplogin")
}




landingPageFilterAPi = (endPoint:any) => {
  let token = localStorage.getItem("token");
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: token
  };
  

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.landingPageFilterAPiEndPoint + "?title=" + endPoint 
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  this.landingPageApiCallID = requestMessage.messageId;
 
  runEngine.sendMessage(requestMessage.id, requestMessage);

};


handleAccordian = (index:any) => {
  this.setState((prevState) => ({
    recipeFaq: prevState.recipeFaq === index ? null : index,
  }));
};



startNowBtn = () => {
  this.props.navigation.navigate("Signuplogin")
}

 getImageSource = (index:any) => {
  if (index === 0) {
    return uploadImage;
  } else if (index === 1) {
    return organized;
  } else if (index === 2) {
    return share;
  } else {
    return cloudDone;
  }
};

handleGetStarted = () => {
  this.props.navigation.navigate('Signuplogin')
}



  // Customizable Area End
}
// Customizable Area End
