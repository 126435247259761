import React from "react";

import {
    Box,
    Button,
    Typography,
    TextField,
    Dialog,
    DialogTitle,
    IconButton,
    Divider,
    DialogActions,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { styled } from "@material-ui/styles"
import { backArrow, bgImage, checkImg, mail } from "./assets";
import Header from "../../../components/src/Header.web";
import Footer from "../../navigationmenu/src/Footer.web";
import CloseIcon from '@material-ui/icons/Close';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
// Customizable Area End

import NewsLetterController, {
    Props,
} from "./NewsLetterController";
import { ErrorMessage, Formik } from "formik";

export default class NewsLetter extends NewsLetterController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick} getHeaderData={() => { }} />
                <NewsLetterStyle>
                    <Box className="mainContentDiv">
                        <Button data-test-id="backArraowClick" className="backButton" onClick={this.navigateBack}>
                            <img src={backArrow} alt="Back" />
                        </Button>
                        <Box style={{ marginLeft: '635px', marginBottom: '30px', width: '155px' }}>
                            <Typography className="newsletterTitle">NEWSLETTER</Typography>
                        </Box>
                        <Formik
                            enableReinitialize
                            data-test-id="formikForm"
                            initialValues={{
                                email: this.state.email,
                            }}
                            validationSchema={this.newsLetterSchema}
                            onSubmit={values => {
                                this.subscribeNewsLetter(values.email);
                            }}>
                            {({ handleSubmit, handleChange, values, isSubmitting }) => (
                                <form className="formContainer" onSubmit={handleSubmit}>
                                    <Box className="mainContainer">
                                        <Box className="innerBox">
                                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginBottom: '30px' }}>
                                                <img src={mail} style={{ height: '80px', width: '80px' }} />
                                            </div>
                                            <Typography className="mainTitle">Suscribe to our Newsletter</Typography>
                                            <Typography className="subTitle">to be updated with all the latest trends and products.</Typography>
                                            <TextField style={{ marginBottom: '15px' }}
                                                type="email"
                                                placeholder="Your e-mail"
                                                variant="outlined"
                                                data-test-id="emailField"
                                                value={values.email}
                                                onChange={handleChange}
                                                autoComplete="off"
                                                name="email"
                                                fullWidth />
                                            <ErrorMessage name="email" component="div" className="errorMessages" />
                                            <Button className="joinBtn" data-test-id="joinBtn" type="submit" disabled={isSubmitting}>Join</Button>
                                        </Box>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>

                    <Dialog open={this.state.showSucessModal} onClose={() => this.setState({ showSucessModal: false })} className="dialogContainer" PaperProps={{
                        style: {
                            position: 'relative',
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: 8,
                            borderBottomRightRadius: 50,
                        }
                    }} >
                        <DialogTitle>
                            <Typography style={{ fontSize: 20, fontFamily: 'Poppins', color: '#1C2110', fontWeight: 700 }}>Newsletter subscription</Typography>
                            <IconButton data-test-id='closeIcon' onClick={this.sucessModalClose} style={{ position: 'absolute', right: 8, top: 8, color: '#666' }}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <Divider />
                        <DialogBoxContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                <Box style={{
                                    backgroundColor: '#34D399',
                                    textAlign: 'center',
                                    height: '130px',
                                    borderRadius: '80px',
                                    display: 'flex',
                                    width: '130px',
                                    alignItems: 'center',
                                    marginTop: '25px',
                                    justifyContent: 'center'
                                }}>
                                    <img className='checkImg' src={checkImg} />
                                </Box>
                            </div>
                            <Box style={{ textAlign: 'center', marginLeft: '10px', marginRight: '10px', marginBottom: '20px', marginTop: '40px' }}>
                                <Typography style={{
                                    fontWeight: 700,
                                    fontFamily: "Poppins",
                                    lineHeight: "28px",
                                    fontSize: "20px",
                                    alignItems: 'center',
                                    color: "#1C2110"
                                }} >You've successfully subscribed to our newsletter</Typography>
                                <Typography style={{
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    fontFamily: "Poppins",
                                    alignItems: 'center',
                                    color: "#1C2110",
                                    marginBottom: '30px',
                                    marginTop: '20px'
                                }} >A confirmation has been sent to your email:
                                    <br />
                                    <span style={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        fontFamily: "Poppins",
                                        color: "#1C2110",
                                        lineHeight: "24px",
                                        alignItems: 'center',
                                    }}>{this.state.email}</span>. Stay tuned for updates</Typography>
                            </Box>
                        </DialogBoxContent>
                        <Divider />
                    </Dialog>

                    <Dialog open={this.state.showErrorModal} onClose={() => this.setState({ showErrorModal: false })} PaperProps={{
                        style: {
                            position: 'relative',
                            height: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            borderRadius: 8,
                            borderBottomRightRadius: 50,
                            width:'545px'
                        }
                    }} >
                        <DialogTitle>
                            <Typography style={{ fontSize: 20, fontFamily: 'Poppins', fontWeight: 700, color: '#1C2110' }}>Newsletter subscription</Typography>
                            <IconButton data-test-id='closeIcon' onClick={this.errorModalClose} style={{
                                right: 8,
                                position: 'absolute',
                                color: '#666',
                                top: 8,
                            }}>
                                <CloseIcon />
                            </IconButton>
                        </DialogTitle>
                        <Divider />
                        <DialogBoxContentBox style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Box style={{
                                    backgroundColor: '#F87171',
                                    textAlign: 'center',
                                    height: '130px',
                                    borderRadius: '80px',
                                    display: 'flex',
                                    width: '130px',
                                    alignItems: 'center',
                                    marginTop: '25px',
                                    justifyContent: 'center'
                                }}>
                                    <HighlightOffIcon style={{ height: '70px', width: '70px', color: 'white' }} />
                                </Box>
                            </div>
                            <Box style={{ textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px', marginTop: '40px' }}>
                                <Typography style={{
                                    fontWeight: 700,
                                    fontFamily: "Poppins",
                                    lineHeight: "28px",
                                    fontSize: "20px",
                                    alignItems: 'center',
                                    color: "#1C2110"
                                }} >Something went wrong</Typography>
                                <Typography style={{
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "24px",
                                    alignItems: 'center',
                                    color: "#1C2110",
                                    marginTop: '20px',
                                }} >Please try again. Make sure your email is correct.
                                </Typography>
                                <Divider style={{ width: '168%', marginLeft: '-110px', marginTop: '25px', color: '#E7E5E4' }} />
                            </Box>
                            <DialogActions style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', margin: '20px 10px 10px 10px' }}>
                                <Button onClick={this.errorModalClose} style={{
                                    backgroundColor: '#F8FFE7',
                                    border: '1px solid #C2E176',
                                    width: '120px',
                                    fontSize: "16px",
                                    height: '56px',
                                    fontFamily: "Poppins",
                                    color: '#8CA74B',
                                    textTransform: "none",
                                    fontWeight: 700,
                                    lineHeight: "24px"
                                }}>Cancel </Button>
                                <Button onClick={this.errorModalClose} style={{
                                    backgroundColor: '#8CA74B',
                                    width: '120px',
                                    height: '56px',
                                    fontSize: "16px",
                                    fontWeight: 700,
                                    color: 'white',
                                    fontFamily: "Poppins",
                                    textTransform: "none",
                                    lineHeight: "24px"
                                }}>Try again</Button>
                            </DialogActions>
                        </DialogBoxContentBox>
                    </Dialog>
                </NewsLetterStyle>
                <Footer navigation={this.props.navigation} id={""} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const DialogBoxContentBox = styled(Box)(() => ({
    display: 'flex',
    height: "auto",
    padding: "20px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: 'relative',
    "@media(max-width: 768px)": {
        padding: "10px",
        height: "240px !important",
    },
}));

const DialogBoxContent = styled(Box)(() => ({
    display: 'flex',
    height: "auto",
    padding: "20px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: 'relative',
    "@media(max-width: 768px)": {
        padding: "10px",
        height: "240px !important",
    },
}));

const NewsLetterStyle = styled("div")({
    "& .mainContentDiv": {
        height: "100vh",
        width: '100%',
        backgroundImage: `url(${bgImage})`,
        backgroundColor: "#8CA74B",
        position: "relative",
        overflow: "hidden",
    },
    "& .backButton": {
        marginLeft: '60px',
        marginTop: '40px'
    },
    "& .mainContainer": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    "& .newsletterTitle": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "28px",
        color: "#FFFFFF",
        width:'132px',
        borderBottom: '1px solid white',
        paddingBottom: '4px'
    },
    "& .errorMessages": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "13px",
        lineHeight: "18px",
        color: '#DC2626',
        width: '100%',
    },
    "& .innerBox": {
        borderRadius: '16px',
        backgroundColor: "#FFFFFF",
        height: '400px',
        width: '490px',
        padding: '40px',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    "& .mainTitle": {
        fontFamily: "Poppins",
        fontWeight: 650,
        fontSize: "30px",
        lineHeight: "40px",
        color: "#1C2110",
        letterSpacing: '-0.5%',
        marginBottom: '9px'
    },
    "& .subTitle": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
        marginBottom: '20px'
    },
    "& .joinBtn": {
        backgroundColor: "#8CA74B",
        height: '56px',
        width: '288px',
        marginTop: '35px',
        borderRadius: '8px',
        '& .MuiButton-label': {
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#FFFFFF",
            textTransform: "none",
        },
    }
});
// Customizable Area End
