import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End


interface S {
  // Customizable Area Start
  selectedView: string,
  drawerOpen: boolean,
  showShareContent: boolean,
  isGroupsExpanded:boolean,
  isPhotoExpanded:boolean,
  isPeopleExpanded:boolean,
  isMemoriesExpanded:boolean,
  isFamilyTreeExpanded:boolean,
  isRecipesExpanded:boolean,
  page:number,
  totalPages:number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SharingDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedView: 'myFiles',
      drawerOpen: false,
      showShareContent: false,
      isGroupsExpanded: false,
      isPhotoExpanded: false,
      isPeopleExpanded: false,
      isMemoriesExpanded: false,
      isFamilyTreeExpanded: false,
      isRecipesExpanded:false,
      page: 1,
      totalPages: 2,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start 
  handleToggle = (event: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView !== null) {
      this.setState({ selectedView: newView });
    }
  };
  handleDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  }

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  }

  handleShareBtn = () => {
    this.setState({ showShareContent: true });
  }

  handleGroupExpand = () => {
    this.setState(prevState => ({
      isGroupsExpanded: !prevState.isGroupsExpanded
    }));
  };

  handlePhotoExpand = () => {
    this.setState(prevState => ({
      isPhotoExpanded: !prevState.isPhotoExpanded
    }));
  };

  handlePeopleExpand = () => {
    this.setState(prevState => ({
      isPeopleExpanded: !prevState.isPeopleExpanded
    }));
  };

  handleMemoriesExpand = () => {
    this.setState(prevState => ({
      isMemoriesExpanded: !prevState.isMemoriesExpanded
    }));
  };

  handleFamilyTreeExpand = () => {
    this.setState(prevState => ({
      isFamilyTreeExpanded: !prevState.isFamilyTreeExpanded
    }));
  };

  handleRecipesExpand = () => {
    this.setState(prevState => ({
      isRecipesExpanded: !prevState.isRecipesExpanded
    }));
  };

  handlePageChange = (event: null, value: number) => {
    this.setState({ page: value });
  };
  // Customizable Area End
}