import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography
} from "@material-ui/core";
import { BackArrowBlack, familyImg, group, home } from "./assets";
import { styled } from "@material-ui/core/styles";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import StorageController, {
    Props,
} from "./StorageController.web";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
// Customizable Area End

// Customizable Area Start
interface DataItem {
    color: string;
    value: number;
    name: string;
}

const dataName: DataItem[] = [
    { name: 'Others', value: 50, color: '#5D6063' },
    { name: 'Photos', value: 50, color: '#8833FF' }
];

const usedStorage = dataName.filter(item => item.name !== 'Free Space');
const totalStorage = usedStorage.reduce((acc, item) => acc + item.value, 0);

const renderCustomLabelText = ({ cx, cy }: any) => {
    return (
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#333" className="midpieText">
            {`${totalStorage} GB`}
        </text>
    );
};
// Customizable Area End

export default class PhotoStorage extends StorageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <PhotoStyle>
                <HeaderDropdown data-test-id="headerComponents" navigation={this.props.navigation} id={""} />
                <Box style={{ padding: '30px', marginLeft: '50px', marginRight: '20px' }}>
                    <Breadcrumbs style={{ marginLeft: '70px' }} separator={<NavigateNextIcon fontSize="small" />}>
                        <Button className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButton">
                            Settings
                        </Button>
                        <Button className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButton" onClick={this.handleSubscription}>
                            Subscription
                        </Button>
                        <Button className="breadcrumbsButton" color="inherit" data-test-id="breadcrumbsButton" onClick={this.handleManageSubscription}>
                            Manage my subscription
                        </Button>
                        <Typography className="breadcrumbsText">
                            Photos
                        </Typography>
                    </Breadcrumbs>
                    <Box style={{ display: 'flex', marginTop: '15px', marginLeft: '10px' }}>
                        <Button className="backButton" data-test-id="backButton" onClick={this.handleManageSubscription}>
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="Back" />
                        </Button>
                        <Typography className="titleTypography">Photos</Typography>
                    </Box>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-around', gap: '40px', marginBottom: '8%' , marginLeft:'10px'}}>
                    <Box style={{ width: '460px' }}>
                        <Typography className="contributersText">Contributors</Typography>
                        <Box style={{ marginTop: '30px' }}>
                            <Box style={{ display: 'flex' }}>
                                <Typography className="familyText"><img style={{ color: '#5D6063', marginRight: '10px' }} src={home} />Family</Typography>
                                <Typography className="storageText"> 30 GB used</Typography>
                            </Box>
                            <Box style={{ display: 'flex', gap: '60px' }}>
                                <Box className="boxContainer">
                                    <img src={familyImg} />
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameText">Sarah</Typography>
                                            <Typography className="gbText">20 GB</Typography>
                                        </Box>
                                        <Button className="expandBotton" style={{maxWidth: 'none !important'}}><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                                <Box className="boxContainer">
                                    <img src={familyImg} />
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameText">Peter</Typography>
                                            <Typography className="gbText">10 GB</Typography>
                                        </Box>
                                        <Button className="expandBotton" style={{maxWidth: 'none !important'}}><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button className="viewMoreBottons">View more</Button>
                            </Box>
                        </Box>
                        <Box>
                            <Box style={{ display: 'flex' }}>
                                <Typography className="familyText"> <img style={{ color: '#5D6063', marginRight: '10px' }} src={group} /> Friends</Typography>
                                <Typography className="storageText"> 10 GB used</Typography>
                            </Box>
                            <Box style={{ display: 'flex', gap: '60px' }}>
                                <Box className="boxContainer">
                                    <img src={familyImg} />
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameText">Karla</Typography>
                                            <Typography className="gbText">3 GB</Typography>
                                        </Box>
                                        <Button className="expandBotton" style={{maxWidth: 'none !important'}}><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                                <Box className="boxContainer">
                                    <img src={familyImg} />
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameText">James</Typography>
                                            <Typography className="gbText">7 GB</Typography>
                                        </Box>
                                        <Button className="expandBotton" style={{maxWidth: 'none !important'}}><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{ justifyContent: 'end', display: 'flex' }}>
                                <Button className="viewMoreBottons">View more</Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="pieChartMainContainer">
                        <Box className="individualsContainer">
                            <Typography className="individualText">Storage</Typography>
                            <Typography className="plansText">Total storage in your plan:
                                <span style={{
                                    color: "#1C2110",
                                    fontWeight: 700,
                                    fontFamily: "Poppins",
                                    fontSize: "16px",
                                    lineHeight: "24px"
                                }}> 100 GB </span>
                            </Typography>
                        </Box>
                        <Box className="pieContainer">
                            <PieChart width={320} height={280}>
                                <Tooltip />
                                <Pie
                                    data={dataName}
                                    dataKey="value"
                                    outerRadius={100}
                                    innerRadius={75}
                                    labelLine={false}
                                    label={renderCustomLabelText}
                                >
                                    {dataName.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                            </PieChart>
                            <Box style={{ marginTop: '-110px' }}>
                                {dataName.map((entry, index) => (
                                    <Box key={`data-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <Box style={{ width: '22px', height: '22px', borderRadius: '85px', backgroundColor: entry.color, marginRight: '10px' }} />
                                        <Typography>
                                            <span style={{
                                                lineHeight: "12px",
                                                fontWeight: 700,
                                                fontFamily: "Poppins",
                                                fontSize: "18px",
                                                color: "#1C2110",
                                            }}>
                                                {entry.name}</span>
                                            <span style={{
                                                color: "#1C2110",
                                                fontWeight: 400,
                                                fontSize: "18px",
                                                fontFamily: "Poppins",
                                                lineHeight: "26px",
                                            }}>: {entry.value} GB used </span></Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box style={{ padding: '10px 30px', marginLeft: '-35px' }}>
                            <Typography className="bottomText">The storage encompasses all personal images as well as those shared with contributors through photos or albums. This comprehensive space ensures a consolidated repository for both personal and collaborative visual content.</Typography>
                        </Box>
                    </Box>
                </Box>

            </PhotoStyle >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const PhotoStyle = styled("div")({
    "& .breadcrumbsButton": {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "22px",
        fontFamily: "Poppins",
        color: "#57534E",
        "& .MuiButton-label": {
            textTransform: "none",
        },
    },
    "& .breadcrumbsText": {
        color: "#1C2110",
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Poppins",
        lineHeight: "22px",
    },
    "& .titleTypography": {
        fontFamily: "Poppins",
        lineHeight: "40px",
        fontSize: "30px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
        fontWeight: 700,
    },
    "& .textBox": {
        display: 'flex'
    },
    "& .pieChartMainContainer": {
        marginRight: '50px',
        width: '620px'
    },
    "& .benefitsBox": {
        marginBottm: '10%',
        marginRight: '15%',
        width: '550px'
    },
    "& .individualsContainer": {
        marginBottom: '40px'
    },
    "& .individualText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        lineHeight: "32px",
        fontSize: "24px",
        letterSpacing: "-0.5%",
        marginBottom: '10px',
        color: "#1C2110"
    },
    "& .plansText": {
        fontWeight: 400,
        fontFamily: "Poppins",
        lineHeight: "24px",
        fontSize: "16px",
        color: "#1C2110",
    },
    "& .pieContainer": {
        display: 'flex',
        padding: '10px',
        alignItems: 'center',
        marginLeft: '-65px'
    },
    "& .midpieText": {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Poppins",
        letterSpacing: "-0.5%",
        lineHeight: "32px",
        color: "#1C2110"
    },
    "& .usedText": {
        fontWeight: 400,
        color: "#000000",
        fontFamily: "Poppins",
        lineHeight: "22px",
        fontSize: "14px"
    },
    "& .contributersText": {
        letterSpacing: "-0.5%",
        fontWeight: 700,
        fontSize: "24px",
        fontFamily: "Poppins",
        color: "#1C2110",
        lineHeight: "32px"
    },
    "& .familyText": {
        fontFamily: "Poppins",
        fontWeight: 700,
        color: "#5D6063",
        fontSize: "18px",
        lineHeight: "26px"
    },
    "& .storageText": {
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#5D6063",
        lineHeight: "24px",
        marginLeft: '30px',
    },
    "& .boxContainer": {
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
        borderRadius: '8px',
        width: '150px',
        padding: '30px',
        marginTop: '30px',
        flexDirection: 'column',
        display: 'flex'
    },
    "& .expandBotton": {
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        height: '24px',
        "& .MuiButton-root": {
            width: "15px",
            maxWidth: 'none !important'
        }
    },
    "& .nameText": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
    },
    "& .gbText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "14px",
        marginTop: '5px',
        color: "#5D6063",
        lineHeight: "22px"
    },
    "& .viewMoreBottons": {
        fontFamily: "Poppins",
        fontWeight: 700,
        lineHeight: "22px",
        fontSize: "14px",
        textTransform: 'none',
        color: "#557C29",
        marginBottom: '20px',
        marginTop: '15px'
    },
    "& .bottomText": {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        color: "#1C2110",
        lineHeight: "24px",
    }

});
// Customizable Area End
