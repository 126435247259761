import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Container, IconButton, styled } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
// Customizable Area End
import MomentsPageController, {
  Props,
} from "./MomentsPageController";
import { recipeBanner, recipeBannerEmpty, recipeDesign1, recipeDesign2, recipeDesign3, recipeDesign4, recipeDesign5, downArrow, recipeDesign6, recipeDesign9, recipeDesign10, familyTreeBg, familyTreeDesign } from "./assets";
import Footer from "../../navigationmenu/src/Footer.web";

export default class RecipsPage extends MomentsPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderFAQSection() {
    const { landingData } = this.state;

    return (
      <Container maxWidth="md" style={{ marginBottom: 40, marginTop: 20 }}>
        <div style={{ width: '100%', maxWidth: 920, margin: 'auto', marginTop: 50 }}>
          {landingData.length > 0 && landingData[0]?.landing_page_faqs?.length > 0 ? (
            landingData[0].landing_page_faqs.map((faq: any, index: any) => (
              <Box
                key={faq.id}
                style={{
                  marginBottom: '16px',
                  borderRadius: '4px',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    padding: '12px',
                    alignItems: 'center',
                    cursor: 'pointer',
                    borderBottom: this.state.recipeFaq === index ? 'none' : '1px solid #D6D3D1',
                  }}
                  onClick={() => this.handleAccordian(index)}
                  data-test-id={`coveracordian-${index + 1}`}
                >
                  <Typography style={webStyles.recipesQuestions}>
                    {faq.question}
                  </Typography>
                  <IconButton
                    aria-expanded={this.state.recipeFaq === index}
                    aria-label="show more"
                    style={{ marginLeft: 'auto', transition: 'transform 0.3s ease' }}
                    size="small"
                  >
                    <img
                      src={downArrow}
                      style={{
                        transform: this.state.recipeFaq === index ? 'rotate(180deg)' : 'rotate(0deg)',
                        width: 24,
                      }}
                    />
                  </IconButton>
                </Box>
                {this.state.recipeFaq === index && (
                  <Box style={{ borderBottom: '1px solid #D6D3D1', padding: '12px' }}>
                    <Typography
                      style={webStyles.faqsAnswers}
                    >
                      {faq.answer}
                    </Typography>
                  </Box>
                )}
              </Box>
            ))
          ) : (
            <Typography>No FAQs available</Typography>
          )}
        </div>
      </Container>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { landingData } = this.state;
    const backgroundStyle = {
      backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${landingData[0]?.background_image_url})`,
      backgroundSize: 'cover',
      width: '100%',
      maxWidth: '1635px',
      backgroundPosition: 'center',
      height: '850px',
      display: 'flex',
      justifyContent: 'end',
    };
    console.log({ landingData }, "=========================landing")
    return (
      //Merge Engine DefaultContainer
      <Box>
        <Header data-test-id="header" onLoginClick={this.onLoginClick} onRegisterClick={this.onRegisterClick}
          getHeaderData={(data) => {
            this.landingPageFilterAPi(data);
          }}
        />
        <Box style={backgroundStyle}>
          <Box style={webStyles.subHeaderBox}>
            <Typography style={webStyles.HeaderTextRecipe}>{landingData[0]?.title}</Typography>
            <Typography style={webStyles.subHeaderRecipe}>
              {landingData[0]?.description}
            </Typography>
            <Button type="submit" style={webStyles.continueButtonRecipe} onClick={this.handleGetStarted}>
              <Typography style={webStyles.continueTextRecipe as any}>Get started</Typography>
            </Button>
          </Box>
        </Box>
        <Box>
          <Box style={{ display: "flex", gap: 80, justifyContent: 'center', marginTop: 60, position: 'relative' }}>
            <Box style={{ width: "100%", maxWidth: "656px" }}>
              <img src={recipeDesign5} style={{ width: "100%", maxWidth: "297px", position: 'absolute', right: 194, top: -120 }} />
              <img src={recipeDesign1} style={{ width: "100%", maxWidth: "285px", position: 'absolute', left: 0, top: -90 }} />
              <img src={recipeDesign3} style={{ width: "100%", maxWidth: "140px", position: 'absolute', right: 0, bottom: 700, height: "356px" }} />

              {landingData[0]?.work_storages?.length > 0 ? (
                <>
                  <Typography style={webStyles.familyTextRecipe as React.CSSProperties}>
                    {landingData[0]?.work_storages[0]?.title}
                  </Typography>
                  <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, color: "#1C2110" }}>
                    {landingData[0]?.work_storages[0]?.description}
                  </Typography>
                </>
              ) : (
                <Typography>No work storage data available</Typography>
              )}

            </Box>
            <img src={recipeBannerEmpty} style={{ maxWidth: "605px", width: "100%", zIndex: 9999 }} />
          </Box>
        </Box>
        <Box style={{ position: "relative" }}>
          <Box style={webStyles.workStorageBox}>
            <img src={recipeDesign2} style={{ width: "100%", position: 'absolute', maxWidth: "230px", left: 0, top: -280, height: 320 }} />
            <img src={recipeDesign6} style={{ width: "100%", maxWidth: "230px", position: 'absolute', right: 0, top: 220, height: 270 }} />
            <img src={recipeBannerEmpty} style={{ maxWidth: "605px", width: "100%", height: 450 }} alt="Recipe Banner" />

            <Box style={webStyles.textContainerRecipe}>
              {landingData[0]?.work_storages?.[1] ? (
                <>
                  <Box>
                    <Typography style={webStyles.familyText2 as React.CSSProperties}>
                      {landingData[0].work_storages[1].title}
                    </Typography>
                  </Box>
                  <Typography style={webStyles.contentTextRecipe as React.CSSProperties}>
                    {landingData[0].work_storages[1].description}
                  </Typography>
                </>
              ) : (
                <Typography>No work storage data available</Typography>
              )}
            </Box>

          </Box>
        </Box>
        <RecipePageStyle style={{ display: 'flex', justifyContent: 'center', marginTop: 100, gap: "50px"}}>
          {landingData?.[0]?.benefits?.map((benefit: any, index: any) => (
            <Box className="priceBox" key={benefit.id}>
              <Typography className="priceTitle">{benefit.title}</Typography>
              <Box className="priceCenterDiv">
                <Typography className="priceSubTitle" dangerouslySetInnerHTML={{ __html: benefit.description }} />
              </Box>
              <Button className="startBtn" onClick={this.startNowBtn}>Start now</Button>
            </Box>
          )) || null}
        </RecipePageStyle>
        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 100, position: "relative" }}>
          <Box style={webStyles.backgroundImageFeature as any}>
          <img src={familyTreeDesign} style={{ width: "100%", position: 'absolute', maxWidth: "160px", right: 0, top: -80, height: 270 }} />
            <img src={recipeDesign2} style={{ width: "100%", maxWidth: "115px", position: 'absolute', left: 0, top: 80, height: 122 }} />
            <img src={recipeDesign10} style={{ width: "100%", maxWidth: "180px", position: 'absolute', right: 0, top: 420, height: 380 }} />

            <Typography style={webStyles.bestFeatures}>The Best Features For Your Recipes</Typography>
            <Typography style={webStyles.bestFeatures2 as any}>MyKinship works as a recipe organizer and meal planner and a recipe management
              turning handwritten recipes into digital recipes for easier storage.</Typography>

            <Box style={{ maxWidth: "950px", width: "100%", marginTop: 50 }}>
              {landingData[0]?.landing_page_features?.length ? (
                landingData[0].landing_page_features.map((feature: any, index: any) => (
                  <Box
                    key={index}
                    style={{
                      backgroundColor: '#FFFFFF',
                      height: 180,
                      display: 'flex',
                      marginBottom: 65,
                      borderRadius: 8,
                      position: 'relative',
                      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                      width:'100%',
                      maxWidth:'950px'
                    }}
                  >
                    <img
                      src={this.getImageSource(index)}
                      data-test-id="imageElement"
                      style={{ width: 60, height: 60, display:'flex', alignSelf:'center', padding: 20 }}
                      alt={`Feature ${index}`}
                    />
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth:'750px', width:"100%", paddingLeft:30 }}>
                      <Typography style={webStyles.recipeFeatureTitle}>
                        {feature.title}
                      </Typography>
                      <Typography style={{ fontWeight: 400, fontFamily: "Poppins", fontSize: 20, }}>
                        {feature.description}
                      </Typography>
                    </Box>
                    <img
                      src={recipeDesign9}
                      style={{
                        width: "100%",
                        maxWidth:"160px",
                        height: 220,
                        top: 130,
                        position: 'absolute',
                        zIndex: "-1",
                        [index % 2 === 0 ? 'right' : 'left']: -20,
                      }}
                      alt="Design 9"
                    />
                  </Box>
                ))
              ) : (
                <Typography>No features available</Typography>
              )}
            </Box>


          </Box>

        </Box>
        <Box style={{marginLeft:50}}>
        <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
          <Typography style={webStyles.frequentlyTextRecipe}>Frequently asked questions</Typography>
          <Typography style={webStyles.simpleTextRecipe}>Simple, transparent pricing that grows with you. Try any plan free for 30 days.</Typography>
        </Box>
        {this.renderFAQSection()}
        <Box style={{ position: 'relative' }}>
          <Box style={{ maxWidth: 1240, width: '100%', margin: 'auto' }}>
            <img src={recipeDesign4} style={{ objectFit: 'contain', position: 'absolute', height: 420, right: 0, top: -80, zIndex: -1 }} />
            <Typography style={webStyles.moreQuestions}>More Questions about Saving Family Recipes?</Typography>
            <Typography style={webStyles.moreQuestions2}>Sign up today to immortalize your family recipes. If you have any additional questions about preserving family recipes and immortalizing memories, please don’t hesitate to contact MyKinship.</Typography>
          </Box>
        </Box>
        </Box>
        <Footer navigation={this.props.navigation} id={""} />

      </Box>

      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    width: "32rem",
    gap: "2rem"
  },
  HeaderTextRecipe: {
    fontSize: 72,
    fontWeight: 400,
    fontFamily: 'Abygaer',
    color: '#305328',
    marginTop: 130,
  },
  subHeaderRecipe: {
    color: '#305328',
    fontWeight: 400,
    fontSize: 24,
    fontFamily: "Poppins",
    marginTop:20
  },
  continueButtonRecipe: {
    backgroundColor: "#8CA74B",
    height: '56px',
    borderRadius: '8px',
    width: '292px',
    marginTop: '52px',
  },
  continueTextRecipe: {
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textTransform: 'none'
  },
  familyTextRecipe: {
    color: "#305328",
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: 48
  },
  familyText2: {
    color: "#305328",
    fontFamily: "Poppins",
    fontWeight: 700,
    fontSize: 48,
    textAlign: 'right',
    width:"100%",
    maxWidth:"625px"
  },
  contentTextRecipe: {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: 20,
    color: "#1C2110"
  },
  textContainerRecipe: {
    width: "100%",
    maxWidth: "656px"
  },
  backgroundImageFeature: {
    backgroundImage: `url(${familyTreeBg})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '1300px',
    display: 'flex',
    backgroundSize: 'cover',
    flexDirection: "column",
    alignItems: 'center',
  },
  bestFeatures: {
    color: "#305328",
    fontFamily: "Poppins",
    fontWeight: 700,
    marginTop: 30,
    fontSize: 48,
  },
  backgroundImage: {
    backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 0.5) 30%, rgba(255, 255, 255, 1) 70%), url(${recipeBanner})`,
    backgroundSize: 'cover',
    width: '100%',
    maxWidth: "1635px",
    height: '614px',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: "end",
  },
  bestFeatures2: {
    color: "#5D6063",
    fontFamily: "Poppins",
    fontWeight: 400,
    width: "100%",
    fontSize: 20,
    textAlign: 'center',
    maxWidth: "900px"
  },
  frequentlyTextRecipe: {
    fontSize: 36,
    fontWeight: 700,
    color: '#1C1917',
    marginTop: 100,
    fontFamily: 'Poppins',
  },
  simpleTextRecipe: {
    fontSize: 18,
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#5D6063'
  },
  moreQuestions: {
    fontWeight: 700,
    fontSize: 36,
    fontFamily: "Poppins",
    color: "#1C1917"
  },
  moreQuestions2: {
    fontWeight: 400,
    fontSize: 18,
    fontFamily: "Poppins",
    color: "#5D6063",
    marginTop: 10,
    marginBottom: 70
  },
  subHeaderBox:
  {
    width: "100%",
    maxWidth: "666px",
    marginRight:50
  },
  faqQuestionsRecipe: {
    flex: 1,
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 700
  },
  workStorageBox:
    { display: "flex", gap: 80, justifyContent: 'center', marginTop: 150 },
  recipeFeatureTitle:
    { fontWeight: 700, fontSize: 44, fontFamily: 'Poppins', color: "#305328" },
  recipesQuestions: { 
    fontFamily: 'Poppins', flex: 1, fontSize: 18, fontWeight: 700 
  },
  faqsAnswers:{
    fontFamily: 'Poppins',
    fontWeight: 400,
    flex: 1,
    fontSize: 16,
    color: '#5D6063',
  }
};

const RecipePageStyle = styled("div")({

  "& .priceMainBox": {
    justifyContent: 'space-between',
    display: 'flex',
    width: '94%',
  },
  "& .priceBox": {
    borderRadius: '16px',
    width: '370',
    height: '380',
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.5)",
  },
  "& .priceTitle": {
    lineHeight: "44px",
    fontWeight: 700,
    fontSize: "36px",
    fontFamily: 'Poppins',
    marginTop: '40px',
    color: '#1C2110',
    letterSpacing: '-1%',
    marginBottom: '5px',
    textAlign: 'center'
  },
  "& .priceSubTitle": {
    fontWeight: 400,
    color: '#1C2110',
    fontSize: "18px",
    fontFamily: 'Poppins',
    lineHeight: "26px",
    textAlign: 'center'
  },
  "& .priceCenterDiv": {
        height: '140px',
        display: "flex",
        textAlign: 'center',
        backgroundColor: "#E8EDDB",
        alignItems: "center",
        marginTop: 40
  },

  "& .priceCenterBox": {
    backgroundColor: "#557C29",
    height: '140px'
  },
  "& .priceTag": {
    lineHeight: "56px",
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: "64px",
    textAlign: 'center',
    letterSpacing: '-1.5%',
    color: '#FFFFFF',
    padding: '13px',
  },
  "& .dollar": {
    lineHeight: "56px",
    fontFamily: 'Inter',
    fontWeight: 300,
    fontSize: "32px",
    color: '#FFFFFF',
    letterSpacing: '-1.5%',
  },
  "& .monthText": {
    lineHeight: "28px",
    fontWeight: 400,
    fontSize: "20px",
    color: '#FFFFFF',
    marginBottom: '20px',
    fontFamily: 'Poppins',
    textAlign: 'center',
    display: "block",
    alignItems: "center",
  },
  "& .storageText": {
    lineHeight: "28px",
    fontWeight: 400,
    fontSize: "20px",
    marginBottom: '40px',
    fontFamily: 'Poppins',
    color: '#1C2110',
    textAlign: 'center',
    marginTop: '20px',
  },
  "& .startBtn": {
    backgroundColor: '#8CA74B',
    borderRadius: '8px',
    marginTop: '40px',
    marginBottom: '48px',
    padding: '10px',
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    textTransform:"none",
    width: '182px',
    "& .Muibutton-label": {
      lineHeight: "24px",
      textAlign: 'center',
      fontWeight: 700,
      fontSize: "16px",
      fontFamily: 'Poppins',
      color: '#FFFFFF',
    }
  }
})




// Customizable Area End
