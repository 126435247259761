import React from "react";

import {
    Box,
    // Customizable Area Start
    Button,
    Typography
} from "@material-ui/core";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { styled } from "@material-ui/core/styles";
import StorageController, {
    Props,
} from "./StorageController.web";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { BackArrowBlack, familyImg, group, home } from "./assets";
import HeaderDropdown from "../../navigationmenu/src/HeaderDropdown.web";
// Customizable Area End

// Customizable Area Start
interface DataItem {
    name: string;
    color: string;
    value: number;
}

const chartData: DataItem[] = [
    { name: 'Others', value: 50, color: '#5D6063' },
    { name: 'Videos', value: 50, color: '#60A5FA' }
];

const usedDataStorage = chartData.filter(item => item.name !== 'Free Space');
const totalUsedStorage = usedDataStorage.reduce((acc, item) => acc + item.value, 0);

const renderCustomLabel = ({ cx, cy }: any) => {
    return (
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#333" className="middlepieTitle">
            {`${totalUsedStorage} GB`}
        </text>
    );
};
// Customizable Area End

export default class RecipesStorage extends StorageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <RecipesStyle>
                <HeaderDropdown data-test-id="headerBox" navigation={this.props.navigation} id={""} />
                <Box style={{ marginLeft: '50px', padding: '30px', marginRight: '20px' }}>
                    <Breadcrumbs style={{ marginLeft: '70px' }} separator={<NavigateNextIcon fontSize="small" />}>
                        <Button className="breadcrumbsBtns" color="inherit" data-test-id="breadcrumbsBtns">
                            Settings
                        </Button>
                        <Button className="breadcrumbsBtns" color="inherit" data-test-id="breadcrumbsBtns" onClick={this.handleSubscription}>
                            Subscription
                        </Button>
                        <Button className="breadcrumbsBtns" color="inherit" data-test-id="breadcrumbsBtns" onClick={this.handleManageSubscription}>
                            Manage my subscription
                        </Button>
                        <Typography className="breadcrumbsTypo">
                            Recipes
                        </Typography>
                    </Breadcrumbs>
                    <Box style={{ marginTop: '15px', display: 'flex', marginLeft: '10px' }}>
                        <Button className="backButton" data-test-id="backButton" onClick={this.handleManageSubscription}>
                            <img style={{ color: 'black' }} src={BackArrowBlack} alt="BackBtn" />
                        </Button>
                        <Typography className="titleText">Recipes</Typography>
                    </Box>
                </Box>
                <Box className="contributorBox">
                    <Box style={{ width: '462px' }}>
                        <Typography className="contributersTextTitle">Contributors</Typography>
                        <Box style={{ marginTop: '30px' }}>
                            <Box style={{ display: 'flex' }}>
                                <Typography className="familyTextTitle"><img style={{ color: '#5D6063', marginRight: '10px' }} src={home} />Family</Typography>
                                <Typography className="storageTextTitle"> 30 GB used</Typography>
                            </Box>
                            <Box style={{ display: 'flex', gap: '60px' }}>
                                <Box className="boxContainer">
                                    <img src={familyImg} />
                                    <Box className="imgBoxs">
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameText">Sarah</Typography>
                                            <Typography className="gbText">20 GB</Typography>
                                        </Box>
                                        <Button className="expandBtn"><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                                <Box className="boxContainer">
                                    <img src={familyImg} />
                                    <Box className="imgBoxs">
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameText">Peter</Typography>
                                            <Typography className="gbText">10 GB</Typography>
                                        </Box>
                                        <Button className="expandBtn"><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{ display: 'flex', justifyContent: 'end' }}>
                                <Button className="viewMoreBtns">View more</Button>
                            </Box>
                        </Box>
                        <Box>
                            <Box style={{ display: 'flex' }}>
                                <Typography className="familyTextTitle"> <img style={{ color: '#5D6063', marginRight: '10px' }} src={group} /> Friends</Typography>
                                <Typography className="storageTextTitle"> 10 GB used</Typography>
                            </Box>
                            <Box style={{ display: 'flex', gap: '60px' }}>
                                <Box className="boxContainer">
                                    <img src={familyImg} />
                                    <Box className="imgBoxs">
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameText">Karla</Typography>
                                            <Typography className="gbText">3 GB</Typography>
                                        </Box>
                                        <Button className="expandBtn"><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                                <Box className="boxContainer">
                                    <img src={familyImg} />
                                    <Box className="imgBoxs">
                                        <Box style={{ marginTop: '20px' }}>
                                            <Typography className="nameText">James</Typography>
                                            <Typography className="gbText">7 GB</Typography>
                                        </Box>
                                        <Button className="expandBtn"><KeyboardArrowRightIcon style={{ color: '#FFFFFF' }} /></Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Box style={{ justifyContent: 'end', display: 'flex' }}>
                                <Button className="viewMoreBtns">View more</Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="pieChartMainBox">
                        <Box className="individualsBoxs">
                            <Typography className="individualsTexts">Storage</Typography>
                            <Typography className="planTexts">Total storage in your plan:
                                <span style={{
                                    fontFamily: "Poppins",
                                    fontWeight: 700,
                                    lineHeight: "24px",
                                    color: "#1C2110",
                                    fontSize: "16px"
                                }}> 100 GB </span>
                            </Typography>
                        </Box>
                        <Box className="pieBoxs">
                            <PieChart height={280} width={320} >
                                <Tooltip />
                                <Pie
                                    dataKey="value"
                                    data={chartData}
                                    innerRadius={75}
                                    outerRadius={100}
                                    label={renderCustomLabel}
                                    labelLine={false}
                                >
                                    {chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={entry.color} />
                                    ))}
                                </Pie>
                            </PieChart>
                            <Box style={{ marginTop: '-110px' }}>
                                {chartData.map((entry, index) => (
                                    <Box key={`data-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                        <Box style={{ width: '22px', height: '22px', borderRadius: '85px', backgroundColor: entry.color, marginRight: '10px' }} />
                                        <Typography>
                                            <span style={{
                                                fontFamily: "Poppins",
                                                fontWeight: 700,
                                                fontSize: "18px",
                                                color: "#1C2110",
                                                lineHeight: "12px",
                                            }}>
                                                {entry.name}</span>
                                            <span style={{
                                                fontFamily: "Poppins",
                                                fontWeight: 400,
                                                color: "#1C2110",
                                                fontSize: "18px",
                                                lineHeight: "26px",
                                            }}>: {entry.value} GB used </span></Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box style={{ padding: '10px 30px', marginLeft: '-35px' }}>
                            <Typography className="bottomText">The storage encompasses all personal images as well as those shared with contributors through photos or albums. This comprehensive space ensures a consolidated repository for both personal and collaborative visual content.</Typography>
                        </Box>
                    </Box>
                </Box>

            </RecipesStyle >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const RecipesStyle = styled("div")({
    "& .contributorBox": {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-around',
        gap: '40px',
        marginBottom: '8%',
        marginLeft:'10px'
    },
    "& .breadcrumbsBtns": {
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Poppins",
        lineHeight: "22px",
        color: "#57534E",
        "& .MuiButton-label": {
            textTransform: "none",
        },
    },
    "& .breadcrumbsTypo": {
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Poppins",
        lineHeight: "22px",
        color: "#1C2110",
    },
    "& .titleText": {
        fontWeight: 700,
        fontFamily: "Poppins",
        lineHeight: "40px",
        fontSize: "30px",
        color: "#1C2110",
        letterSpacing: "-0.5%",
    },
    "& .benefitsBox": {
        width: '550px',
        marginRight: '15%',
        marginBottm: '10%'
    },
    "& .individualsBoxs": {
        marginBottom: '40px'
    },
    "& .imgBoxs": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    "& .textBox": {
        display: 'flex'
    },
    "& .pieChartMainBox": {
        width: '620px',
        marginRight: '50px'
    },
    "& .individualsTexts": {
        fontFamily: "Poppins",
        fontWeight: 700,
        lineHeight: "32px",
        fontSize: "24px",
        letterSpacing: "-0.5%",
        marginBottom: '10px',
        color: "#1C2110"
    },
    "& .planTexts": {
        fontWeight: 400,
        fontSize: "16px",
        fontFamily: "Poppins",
        color: "#1C2110",
        lineHeight: "24px"
    },
    "& .pieBoxs": {
        marginLeft: '-45px',
        display: 'flex',
        padding: '10px',
        alignItems: 'center'
    },
    "& .usedText": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "22px",
        color: "#000000",
    },
    "& .middlepieTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        lineHeight: "32px",
        color: "#1C2110",
        fontSize: "24px",
        letterSpacing: "-0.5%",
    },
    "& .storageTextTitle": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#5D6063",
        marginLeft: '30px'
    },
    "& .contributersTextTitle": {
        fontFamily: "Poppins",
        fontWeight: 700,
        lineHeight: "32px",
        fontSize: "24px",
        letterSpacing: "-0.5%",
        color: "#1C2110"
    },
    "& .familyTextTitle": {
        fontWeight: 700,
        fontSize: "18px",
        fontFamily: "Poppins",
        color: "#5D6063",
        lineHeight: "26px"
    },
    "& .boxContainer": {
        borderRadius: '8px',
        boxShadow: "0 0 10px 5px rgba(0, 0, 0, 0.07)",
        padding: '30px',
        width: '150px',
        marginTop: '30px',
        display: 'flex',
        flexDirection: 'column'
    },
    "& .expandBtn": {
        backgroundColor: '#8CA74B',
        borderRadius: '8px',
        height: '24px'
    },
    "& .nameText": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "22px",
        color: "#1C2110",
    },
    "& .gbText": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "22px",
        color: "#5D6063",
        marginTop: '5px'
    },
    "& .viewMoreBtns": {
        fontWeight: 700,
        fontFamily: "Poppins",
        fontSize: "14px",
        lineHeight: "22px",
        color: "#557C29",
        textTransform: 'none',
        marginTop: '15px',
        marginBottom: '20px'
    },
    "& .bottomText": {
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C2110",
    }

});
// Customizable Area End
